import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Alignment,
  CommonButton,
  ComponentCard,
  Font20PX,
  LayoutBox,
  LightFont,
  MediumText,
  NumberWrapper,
  SmallText,
  StyledCard,
  StyledDiv,
} from "../styles/appstyles";
import Input from "../components/Input";
import { GlobalContext } from "../context/GlobalContext";
import SelectComponent from "../components/SelectComponent";
import AddressSuggestionInput from "../components/AddressSuggestionInput";
import { useNavigate } from "react-router-dom";
import mapboxgl from "mapbox-gl";
import ReactMapGL, { Marker, NavigationControl, Layer } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { FaMapMarkerAlt } from "react-icons/fa";
import axios from "axios";
import { FiHome, FiLayers, FiUser, FiArrowRight } from "react-icons/fi";

// import Cookies from "js-cookie";

const BuildingDetails = () => {
  const {
    sendBuildingDetails,
    buildingDetails,
    // sendCreatedBuilding,
    sendNewStructure,
    createdBuildingData,
    sendStandardResponse,
  } = useContext(GlobalContext);
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState({
    address: buildingDetails?.address,
    buildingType: buildingDetails?.buildingType,
    floor_area: buildingDetails?.floor_area,
    buildingOwnership: buildingDetails?.buildingOwnership,
  });

  // eslint-disable-next-line import/no-webpack-loader-syntax
  mapboxgl.workerClass =
    // eslint-disable-next-line import/no-webpack-loader-syntax
    require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

  const mapRef = useRef();

  useEffect(() => {
    if (createdBuildingData?.latitude && createdBuildingData?.longitude) {
      mapRef?.current?.flyTo({
        center: [createdBuildingData?.longitude, createdBuildingData?.latitude],
        zoom: 14,
      });
    }
  }, [createdBuildingData]);

  useEffect(() => {
    sendBuildingDetails(inputValue);
  }, [inputValue, sendBuildingDetails]);

  const handleInputChange = (value, name) => {
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const token = JSON.parse(localStorage.getItem("access-token"));

  const fetchResult = async (buildingId) => {
    const response = await axios.get(
      `https://blueurbn-backend.app/core/buildings/${buildingId}/standardresult/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );

    sendStandardResponse(response?.data);
    localStorage?.setItem(
      "standardResponseFromLocal",
      JSON.stringify(response?.data)
    );

    let newStructure = {};

    response?.data?.forEach((result) => {
      newStructure = { ...newStructure, [result?.design_type]: result };
    });
    sendNewStructure(newStructure);

    localStorage?.setItem("newStructure", JSON.stringify(newStructure));
    navigate("building-analysis");
  };

  const parkLayer = {
    id: "landuse_park",
    type: "fill-extrusion",
    source: "mapbox",
    "source-layer": "landuse",
    filter: ["==", "class", "park"],
    paint: {
      "fill-color": "#4E3FC8",
    },
  };

  const developBuilding = async () => {
    let data = new FormData();
    data.append("userinput_type", inputValue?.buildingType);
    data.append("floorarea", inputValue?.floor_area);
    data.append("address", inputValue?.address);

    const response = await axios.post(
      `https://blueurbn-backend.app/core/buildings/`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );

    if (response?.status === 200) {
      await axios.get(
        `https://blueurbn-backend.app/core/buildings/${response?.data?.building?.id}/standard/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      localStorage.setItem("id", response?.data?.id);
      fetchResult(response?.data?.building?.id);
    }
  };

  var o = Object.create({});

  Object.defineProperty(o, "foo", {
    value: 42,
    enumerable: true,
    configurable: true,
  });

  mapboxgl.accessToken =
    "pk.eyJ1IjoicmlzaGFiaGd1cHRhYmx1ZXVyYm4iLCJhIjoiY2xuYWVoMTc0MDJ0aTJqbzdlcHJmdWtjNCJ9._mCic9aDZ6TIJrpcBZ28rw";

  console.log("inputValue", inputValue);
  return (
    <>
      <ComponentCard
        style={{
          width: window?.innerWidth > 1350 ? "84%" : "76%",
          margin: window?.innerWidth > 1350 ? "0px 0px 0px auto" : "0px",
          overflowX: "hidden",
          position: "relative",
        }}
      >
        <Alignment
          padding="19px 32px"
          style={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
            // borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          }}
        >
          <NumberWrapper>
            <MediumText
              style={{
                fontSize: "14px",
                lineHeight: "18.24px",
                color: "white",
              }}
            >
              1
            </MediumText>
          </NumberWrapper>
          <Font20PX style={{ color: "#343541" }}>Address</Font20PX>
        </Alignment>

        <LayoutBox
          style={{
            maxHeight: "80%",
            // borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            overflowY: "scroll",
          }}
        >
          <Alignment padding="24px 32px">
            <Alignment margin="0px 0px 16px">
              <SmallText
                style={{
                  fontSize: "28px",
                  lineHeight: "42px",
                  fontWeight: "700",
                }}
              >
                Let’s get you started right away!
              </SmallText>
            </Alignment>
            <LightFont style={{ color: "#85868D" }}>
              Kindly input your building address for a detailed analysis,
              allowing us to offer precise energy solution recommendations
            </LightFont>
            <Alignment padding="24px 0px">
              <AddressSuggestionInput
                handleInputChange={handleInputChange}
                inputValue={inputValue}
              />
            </Alignment>

            {inputValue?.address ? (
              <Alignment>
                <Alignment padding="0px 0px 24px">
                  <SelectComponent
                    Options={[
                      { name: "Warehouse", value: "Warehouse" },
                      { name: "Office", value: "Office" },
                    ]}
                    label="BUILDING TYPE"
                    icon={<FiHome />}
                    placeholder="Select a building type"
                    name="buildingType"
                    handleInputChange={handleInputChange}
                    value={inputValue?.buildingType}
                  />
                </Alignment>
              </Alignment>
            ) : null}

            {inputValue?.buildingType ? (
              <Alignment padding="0px 0px 24px">
                <Input
                  handleInputChange={handleInputChange}
                  name="floor_area"
                  label="TOTAL FLOOR AREA"
                  icon={<FiLayers />}
                  unit="sq.ft."
                  value={inputValue?.floor_area}
                  type="number"
                />
              </Alignment>
            ) : null}

            {inputValue?.floor_area ? (
              <Alignment padding="0px 0px 24px">
                <SelectComponent
                  Options={[
                    { name: "Owner", value: "Owner" },
                    { name: "Tenant", value: "Tenant" },
                    { name: "Public", value: "Public" },
                  ]}
                  label="BUILDING OWNERSHIP"
                  icon={<FiUser />}
                  placeholder="Who owns this building?"
                  name="buildingOwnership"
                  handleInputChange={handleInputChange}
                  value={inputValue?.buildingOwnership}
                />
              </Alignment>
            ) : null}
          </Alignment>
          <Alignment padding="32px " style={{ width: "100vw", height: "72vh" }}>
            <ReactMapGL
              ref={mapRef}
              mapboxApiAccessToken="pk.eyJ1IjoicmlzaGFiaGd1cHRhYmx1ZXVyYm4iLCJhIjoiY2xuYWVoMTc0MDJ0aTJqbzdlcHJmdWtjNCJ9._mCic9aDZ6TIJrpcBZ28rw"
              optimizeForTerrain={false}
              styleDiffing={false}
              terrain={{ source: "mapbox-dem", exaggeration: 2.0 }}
              initialViewState={{
                longitude: createdBuildingData?.longitude ?? 0,
                latitude: createdBuildingData?.latitude ?? 0,
                zoom: 12,
              }}
              attributionControl="false"
              // mapStyle="mapbox://styles/mapbox/streets-v12"
              mapStyle="mapbox://styles/mapbox/streets-v9"
              style={{ borderRadius: "20px" }}
            >
              <>
                <Layer {...parkLayer} />

                {inputValue?.address ? (
                  <Marker
                    longitude={createdBuildingData?.longitude ?? 0}
                    latitude={createdBuildingData?.latitude ?? 0}
                    anchor="bottom"
                  >
                    <StyledDiv
                      onMouseOver={() => setHover(true)}
                      onMouseLeave={() => {
                        setTimeout(() => {
                          setHover(false);
                        }, 500);
                      }}
                      style={{}}
                    >
                      <FaMapMarkerAlt
                        style={{ height: "42px", width: "42px", color: "red" }}
                      />
                    </StyledDiv>
                    {hover ? (
                      <Alignment
                        style={{
                          position: "absolute",
                          top: "-40px",
                          width: "10rem",
                          textAlign: "center",
                        }}
                      >
                        <StyledCard>
                          <Alignment>
                            <MediumText style={{ fontSize: "14px" }}>
                              {inputValue?.address}
                            </MediumText>
                          </Alignment>
                        </StyledCard>
                      </Alignment>
                    ) : null}
                  </Marker>
                ) : null}
              </>

              <NavigationControl position="bottom-right" />
            </ReactMapGL>
          </Alignment>
        </LayoutBox>
        <Alignment
          margin="1px 32px"
          style={{
            textAlign: "right",
            margin: "24px 24px",
            position: "absolute",
            bottom: "-10px",
            right: "0px",
            background: "white",
            width: "100%",
            borderTop: "1px solid rgba(0, 0, 0, 0.12)",
          }}
        >
          <CommonButton
            className={
              !inputValue?.address ||
              !inputValue?.buildingType ||
              !inputValue?.buildingOwnership ||
              !inputValue?.floor_area
                ? "disabled"
                : "enabled"
            }
            onClick={() => {
              if (
                inputValue?.address &&
                inputValue?.buildingType &&
                inputValue?.buildingOwnership &&
                inputValue?.floor_area
              ) {
                developBuilding();
              }
            }}
            style={{ margin: "12px 12px 6px" }}
          >
            Analyse Your Building{" "}
            <FiArrowRight style={{ position: "relative", top: "4px" }} />
          </CommonButton>
        </Alignment>
      </ComponentCard>
    </>
  );
};

export default BuildingDetails;

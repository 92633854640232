import React, { createContext, useReducer } from "react";
import reducer from "./reducer";
const initialState = {
  index: 0,
  buildingDetails: null,
  buildingAnalysisData: null,
  recommendationsData: null,
  policyIncentives: false,
  placeDetail: null,
  standardResponse: [],
  energyEmission: "",
  createdBuildingData: {
    latitude: 37.7749,
    longitude: -122.4194,
  },
  costSavings: 0,
  recommendationsMetrics: {
    emissions: 0,
    payback: 0,
    savings: 0,
    investment: 0,
  },
  newStructure: 0,
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const sendIndex = (index) => {
    dispatch({ type: "SEND_INDEX", payload: index });
  };

  const sendBuildingDetails = (details, source) => {
    // if (source === "placesApi") {
    //   dispatch({
    //     type: "SEND_BUILDING_DETAILS",
    //     payload: { ...state, address: details },
    //   });
    // } else {
    dispatch({ type: "SEND_BUILDING_DETAILS", payload: details });
    // }
  };

  const sendPlacesDetails = (placeDetail) => {
    dispatch({ type: "SEND_PLACES_DETAILS", payload: placeDetail });
  };

  const sendBuildingAnalysisData = (analysisDetails) => {
    dispatch({ type: "SEND_BUILDING_ANALYSIS_DATA", payload: analysisDetails });
  };

  const sendCreatedBuilding = (buildingData) => {
    dispatch({ type: "SEND_CREATED_BUILDING_DATA", payload: buildingData });
  };

  const sendStandardResponse = (standardResponse) => {
    dispatch({ type: "SEND_STANDARD_RESPONSE", payload: standardResponse });
  };

  const sendNewStructure = (response) => {
    dispatch({ type: "SEND_NEW_STRUCTURE", payload: response });
  };

  const sendEnergyEmission = (energyEmissions) => {
    dispatch({ type: "SEND_ENERGY_EMISSION", payload: energyEmissions });
  };

  const sendCostSavings = (costSavings) => {
    dispatch({ type: "SEND_COST_SAVINGS", payload: costSavings });
  };

  const sendPolicyIncentives = (displayPolicyIncentives) => {
    dispatch({
      type: "DISPLAY_POLICY_INCENTIVES",
      payload: displayPolicyIncentives,
    });
  };

  const sendRecommendationsMetrics = (
    emissions,
    payback,
    investment,
    savings
  ) => {
    dispatch({
      type: "RECOMMENDATIONS_METRICS",
      payload: { emissions, payback, investment, savings },
    });
  };

  const sendRecommendationsData = (recommendationsData) => {
    const recommendations = Object?.entries?.(recommendationsData)
      ?.filter(([key, value]) => value)
      .map((item) => item[0])
      .join(", ");

    dispatch({
      type: "SEND_RECOMMENDATIONS_DATA",
      payload: { recommendations, recommendationsData },
    });
  };

  return (
    <GlobalContext.Provider
      value={{
        sendIndex,
        index: state.index,
        sendBuildingDetails,
        buildingDetails: state.buildingDetails,
        sendBuildingAnalysisData,
        buildingAnalysisData: state.buildingAnalysisData,
        sendRecommendationsData,
        recommendationsData: state?.recommendationsData,
        sendPolicyIncentives,
        policyIncentives: state?.policyIncentives,
        sendPlacesDetails,
        placeDetail: state?.placeDetail,
        sendCreatedBuilding,
        createdBuildingData: state?.createdBuildingData,
        sendStandardResponse,
        standardResponse: state?.standardResponse,
        energyEmission: state?.energyEmission,
        sendEnergyEmission,
        sendCostSavings,
        costSavings: state?.costSavings,
        sendRecommendationsMetrics,
        recommendationsMetrics: state?.recommendationsMetrics,
        sendNewStructure,
        newStructure: state?.newStructure,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

import React, { useContext } from "react";
import {
  Alignment,
  LayoutBox,
  MediumText,
  StyledIcon,
  StyledTextBox,
  // CheckButton,
  CommonButton,
} from "../styles/appstyles";
import { IconLabel } from "../components/componentsStyles";
import { GlobalContext } from "../context/GlobalContext";
import { CiCircleInfo } from "react-icons/ci";
import { AiOutlineArrowRight } from "react-icons/ai";

const SolutionsCard = ({ content }) => {
  const { sendPolicyIncentives } = useContext(GlobalContext);

  return (
    <LayoutBox
      style={{
        padding: "24px 12px",
        alignItems: "center",
        gap: "16px",
        background: "#F4F7FB",
        borderRadius: "12px",
        cursor: null,
        position: "relative",
      }}
      onClick={() => {
        if (content?.color) {
          sendPolicyIncentives(true);
        }
      }}
    >
      {/* policy incentives button */}
      {content?.policyIncentives ? (
        <Alignment
          margin="32px 24px 0px"
          style={{ position: "absolute", top: "58px", left: "30px" }}
        >
          <CommonButton
            style={{
              background: "#ffffff",
              border: "1px solid #2750CD",
              color: "black",
              padding: "8px 4px",
            }}
            onClick={() => sendPolicyIncentives(true)}
          >
            <Alignment
              margin="0px 8px 0px"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "2px",
                color: "#2750CD",
                fontSize: "12px",
              }}
            >
              View Policies
              <AiOutlineArrowRight
                style={{ margin: "0px 0px 0px", color: "#2750CD" }}
              />
            </Alignment>
          </CommonButton>
        </Alignment>
      ) : null}

      {window?.innerWidth > 1390 ? (
        <StyledIcon>
          <img src={content?.icon} alt={content.title} />
        </StyledIcon>
      ) : null}
      <StyledTextBox>
        <div
          style={{
            display: "flex",
            gap: "4px",
            marginBottom: "8px",
          }}
        >
          <IconLabel
            style={{ color: "#343541", opacity: "0.6", fontWeight: "500" }}
          >
            {content.title}
          </IconLabel>
          <>
            <CiCircleInfo style={{ position: "relative", bottom: "1px" }} />
          </>
        </div>
        <Alignment margin="0px 0px 4px">
          <MediumText
            style={{
              fontSize: "24px",
              lineHeight: "31.27px",
              fontWeight: "600",
            }}
          >
            {content?.metric}
          </MediumText>
        </Alignment>
      </StyledTextBox>
    </LayoutBox>
  );
};

export default SolutionsCard;

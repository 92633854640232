import React, { useContext } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
// import { STANDARD_RESULT } from "../constants";
import { GlobalContext } from "../context/GlobalContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
    },
  },
  scales: {
    y: {
      title: {
        display: true,
        text: "Energy consumption (MWh)",
        font: {
          size: 13,
          weight: "bold",
        },
      },
      grid: {
        drawBorder: true,
        // color: (context) => {
        //   if (context.index === 0) {
        //     return "";
        //   } else {
        //     return "";
        //   }
        // },
      },
    },
    x: {
      grid: {
        drawBorder: false,
        color: (context) => {
          if (context.index === 0) {
            return "";
          } else {
            return "";
          }
        },
      },
    },
  },
  maintainAspectRatio: false,
};

const BarChart = () => {
  const { standardResponse } = useContext(GlobalContext);
  const computeData = () => {
    let sum = 0;
    const energySaved = standardResponse?.map((item, index) => {
      if (index > 0) {
        return standardResponse?.[0]?.electricity - item?.electricity;
      } else return null;
    });

    energySaved?.forEach((data) => {
      sum = sum + data;
    });

    const afterReduction = (standardResponse?.[0]?.electricity - sum) / 1000;

    localStorage?.setItem(
      "energySaved",
      JSON.stringify({
        energySaved,
        totalEnergySaved: sum,
        afterReduction,
      })
    );
    return Math.round(afterReduction * 10) / 10;
  };

  const labels = ["Current", "With BlueUrbn"];
  console.log("standardResponse", standardResponse);
  const data = {
    labels,
    datasets: [
      {
        label: "Today",
        data: labels.map((_, index) => {
          if (index === 1) {
            return computeData();
          } else
            return (
              Math.round((standardResponse?.[0]?.electricity / 1000) * 10) / 10
            );
        }),
        backgroundColor: ["rgba(211, 126, 126, 0.4)", "rgba(39, 80, 205, 0.4)"],
        borderWidth: 1,
        borderRadius: 7,
        barPercentage: 1,
      },
    ],
  };

  return (
    <Bar
      options={options}
      data={data}
      style={{ width: "43vw", height: "54vh" }}
    />
  );
};

export default BarChart;

/* eslint-disable no-undef */
import React from "react";
import {
  IconLabel,
  // OptionWrappper,
  // SelectWrapper,
  // StyledOption,
  // StyledSelect,
} from "./componentsStyles";
import { MenuItem, Select } from "@mui/material";
import { Alignment } from "../styles/appstyles";
import { CiCircleInfo } from "react-icons/ci";

const SelectComponent = ({
  Options,
  label,
  handleInputChange,
  icon,
  placeholder,
  name,
  value,
}) => {
  return (
    <>
      <div style={{ display: "flex", gap: "6px", marginBottom: "8px" }}>
        <IconLabel style={{ color: "#343541", opacity: "0.6" }}>
          {label}
        </IconLabel>
        <>
          <CiCircleInfo style={{ position: "relative", bottom: "1px" }} />
        </>
      </div>
      <Alignment style={{ position: "relative" }}>
        <Alignment style={{ position: "absolute", top: "20px", left: "16px" }}>
          {icon}
        </Alignment>
        <Select
          value={value}
          onChange={(selected) => {
            handleInputChange(selected.target.value, name);
          }}
          displayEmpty
          sx={{
            borderRadius: "12px",
            // width: "298px",
            padding: "0px 38px",
          }}
          fullWidth
          inputProps={{ "aria-label": "Without label" }}
          renderValue={(selected) => {
            if (selected?.length === 0) {
              return <div style={{ opacity: 0.8 }}>{placeholder}</div>;
            }
            return selected;
          }}
        >
          {Options?.map((option, index) => {
            return <MenuItem value={option?.value}>{option?.name}</MenuItem>;
          })}
        </Select>
      </Alignment>
    </>
  );
};

export default SelectComponent;

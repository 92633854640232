// import React, { useContext } from "react";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";
// import { Bar } from "react-chartjs-2";
// import { GlobalContext } from "../context/GlobalContext";
// // import { STANDARD_RESULT } from "../constants";
// // import { GlobalContext } from "../context/GlobalContext";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// );

// export const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       display: false,
//     },
//     title: {
//       display: true,
//     },
//   },
//   scales: {
//     y: {
//       grid: {
//         drawBorder: false,
//         color: (context) => {
//           if (context.index === 0) {
//             return "";
//           } else {
//             return "";
//           }
//         },
//       },
//       ticks: {
//         labels: "Something",
//         callback: function (label, index, labels) {
//           console.log("labels", labels);
//           return "$" + label;
//         },
//       },
//     },
//     x: {
//       grid: {
//         drawBorder: false,
//         color: (context) => {
//           if (context.index === 0) {
//             return "";
//           } else {
//             return "";
//           }
//         },
//       },
//     },
//   },
//   maintainAspectRatio: false,
// };

// // const standardResponse = JSON.parse(
// //   localStorage?.getItem("standardResponseFromLocal")
// // );

// const CostSavingChart = () => {
//   const { standardResponse, sendCostSavings } = useContext(GlobalContext);

//   const computeData = () => {
//     let costEmissions = 0;
//     const computedData = standardResponse?.map((data, index) => {
//       if (index > 0) {
//         const computeCostSavings =
//           ((standardResponse?.[0]?.electricity - data?.electricity) * 190) /
//           1000;
//         return Math.round(computeCostSavings * 10) / 10;
//       } else return 0;
//     });

//     computedData?.forEach((data) => (costEmissions = costEmissions + data));

//     console.log("standardResponse101", costEmissions, computedData);
//     sendCostSavings(
//       Math.round(
//         ((standardResponse?.[0]?.electricity * 190) / 1000 - costEmissions) * 10
//       ) / 10
//     );
//     return (
//       Math.round(
//         ((standardResponse?.[0]?.electricity * 190) / 1000 - costEmissions) * 10
//       ) / 10
//     );
//   };

//   const labels = ["TODAY", "AFTER REDUCTION"];

//   const data = {
//     labels,
//     datasets: [
//       {
//         label: "Dataset 1",
//         data: labels.map((_, index) => {
//           if (index === 1) {
//             return computeData();
//           } else return (standardResponse?.[0]?.electricity / 1000) * 190;
//         }),
//         backgroundColor: ["#FEDC8F", "#CBF0A6"],
//         borderWidth: 1,
//         borderRadius: 7,
//         barPercentage: 1,
//       },
//     ],
//   };

//   return (
//     <Bar
//       options={options}
//       data={data}
//       style={{ width: "45vw", height: "64vh" }}
//     />
//   );
// };

// export default CostSavingChart;

import React, { useContext } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
// import { STANDARD_RESULT } from "../constants";
import { GlobalContext } from "../context/GlobalContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
    },
  },
  scales: {
    y: {
      title: {
        display: true,
        text: "Energy consumption (MWh)",
        font: {
          size: 13,
          weight: "bold",
        },
      },
      grid: {
        drawBorder: true,
        // color: (context) => {
        //   if (context.index === 0) {
        //     return "";
        //   } else {
        //     return "";
        //   }
        // },
      },
    },
    x: {
      grid: {
        drawBorder: false,
        color: (context) => {
          if (context.index === 0) {
            return "";
          } else {
            return "";
          }
        },
      },
    },
  },
  maintainAspectRatio: false,
};

const CostSavingsChart = () => {
  const { standardResponse } = useContext(GlobalContext);
  const computeData = () => {
    let costEmissions = 0;
    const computedData = standardResponse?.map((data, index) => {
      if (index > 0) {
        const computeCostSavings =
          ((standardResponse?.[0]?.electricity - data?.electricity) * 190) /
          1000;
        return Math.round(computeCostSavings * 10) / 10;
      } else return 0;
    });

    computedData?.forEach((data) => (costEmissions = costEmissions + data));

    return (
      Math.round(
        ((standardResponse?.[0]?.electricity * 190) / 1000 - costEmissions) * 10
      ) / 10
    );
  };

  const labels = ["Current", "With BlueUrbn"];

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: labels.map((_, index) => {
          if (index === 1) {
            return computeData();
          } else return (standardResponse?.[0]?.electricity / 1000) * 190;
        }),
        backgroundColor: ["rgba(211, 126, 126, 0.4)", "rgba(39, 80, 205, 0.4)"],
        borderWidth: 1,
        borderRadius: 7,
        barPercentage: 1,
      },
    ],
  };

  return (
    <Bar
      options={options}
      data={data}
      style={{ width: "43vw", height: "54vh" }}
    />
  );
};

export default CostSavingsChart;

import styled from "styled-components";

export const InputWrapper = styled.div`
  position: relative;
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 17px;
  left: 19px;
  img {
    width: 18px;
    height: 18px;
  }
`;

export const StyledInput = styled.input`
  padding: 16px;
  border-radius: 12px;
  border: 1px solid black;
  width: 323px;
  padding-left: 50px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Plus Jakarta Sans", sans-serif;
  &:focus {
    border: 2px solid #1876d1;
    -webkit-transition: 0.5s;
    transition: 0.2s;
    outline: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const IconLabel = styled.div`
  font-size: 12px;
  font-family: "Plus Jakarta Sans", sans-serif;
  line-height: 15.64px;
`;

export const SelectWrapper = styled.div``;

export const StyledSelect = styled.select`
  padding: 16px;
  border-radius: 12px;
  width: 104.8%;
  font-size: 16px;
  font-family: HK-Grotesk-Bold;
  line-height: 32px;

  &.select-selected {
    background-color: DodgerBlue;
  }
`;

export const OptionWrappper = styled.div``;
export const StyledOption = styled.option`
  font-size: 16px;
  font-family: HK-Grotesk-Bold;
  line-height: 32px;
  padding: 100px;
`;

export const UnitContainer = styled.div`
  position: absolute;
  bottom: 18px;
  right: 4%;
  @media screen only and (max-width: 1400px) {
    right: 25px;
  }
`;

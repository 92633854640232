import React, { useContext } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { GlobalContext } from "../context/GlobalContext";
// import { STANDARD_RESULT } from "../constants";
// import { GlobalContext } from "../context/GlobalContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
    },
  },
  scales: {
    y: {
      title: {
        display: true,
        text: "Emissions (tons of CO2 / year)",
        font: {
          size: 13,
          weight: "bold",
        },
      },
      grid: {
        drawBorder: true,
        // color: (context) => {
        //   if (context.index === 0) {
        //     return "";
        //   } else {
        //     return "";
        //   }
        // },
      },
    },
    x: {
      grid: {
        drawBorder: false,
        color: (context) => {
          if (context.index === 0) {
            return "";
          } else {
            return "";
          }
        },
      },
    },
  },
  maintainAspectRatio: false,
};

const FloatingChart = () => {
  const { standardResponse } = useContext(GlobalContext);
  const labels = [
    "Baseline",
    "Lighting",
    "Controls",
    "HVAC",
    "Insulation",
    "Solar",
    ["With", "BlueUrbn"],
  ];

  const computeData = () => {
    // const energySaved = JSON.parse(localStorage?.getItem("energySaved"));

    const filteredData = standardResponse?.filter(
      (item) =>
        item.design_type === "baseline" || item?.design_type === "lighting"
    );

    const resultWithoutLighting = standardResponse?.filter(
      (item) =>
        item.design_type !== "baseline" && item?.design_type !== "lighting"
    );

    const updatedSimulationResult = [...filteredData, ...resultWithoutLighting];

    console.log("lighting111", filteredData, updatedSimulationResult);

    //find electricity savings

    const energySaved = updatedSimulationResult?.map((item, index) => {
      if (index > 0) {
        return standardResponse?.[0]?.electricity - item?.electricity;
      } else return 0;
    });
    const emissionsSaved = energySaved.map((data, index) => {
      if (index > 0) {
        return (data / 1000) * 0.364;
      } else
        return (
          Math.round((standardResponse?.[0]?.electricity / 1000) * 0.364 * 10) /
          10
        );
    });

    let sum = 0;

    const totalEnergySaved = energySaved?.forEach((item) => (sum = sum + item));

    const newConsumption =
      ((standardResponse?.[0]?.electricity - totalEnergySaved) / 1000) * 0.364;

    emissionsSaved?.push(newConsumption);
    let prevValue = 0;
    const subtractedData = emissionsSaved?.map((item, index) => {
      if (index === 0) {
        prevValue = item;
        return [item, 0];
      } else {
        const plottedValue = [
          Math.round(prevValue * 10) / 10,
          Math.round((prevValue - item) * 10) / 10,
        ];
        prevValue = prevValue - item;
        return plottedValue;
      }
    });

    return subtractedData;
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: computeData(),
        backgroundColor: [
          "rgba(209, 216, 224, 1)",
          "rgba(255, 123, 49, 0.4)",
          "rgba(147, 95, 255, 0.4)",
          "rgba(109, 178, 39, 0.4)",
          "rgba(26, 184, 250, 0.4)",
          "rgba(214, 162, 47, 0.4)",
          "rgba(39, 80, 205, 1)",
        ],
        borderWidth: 1,
        borderRadius: 7,
        barPercentage: 1,
        margin: 20,
      },
    ],
  };
  return (
    <Bar
      options={options}
      data={data}
      style={{ width: "43vw", height: "54vh" }}
    />
  );
};

export default FloatingChart;

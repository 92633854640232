import React, { useContext, useEffect, useState } from "react";
import {
  Alignment,
  ComponentCard,
  Font20PX,
  LayoutBox,
  LightFont,
  MediumText,
  SmallText,
  StyledHeader,
  StyledIcon,
} from "../styles/appstyles";
import {
  IconContainer,
  InputWrapper,
  StyledInput,
} from "../components/componentsStyles";
import { FINANCING_OPTIONS, UPDATED_INCENCTIVES } from "../constants";
import { DataGrid } from "@mui/x-data-grid";
import SolutionsCard from "../components/SolutionsCard";
import { GlobalContext } from "../context/GlobalContext";
import { RxCross2 } from "react-icons/rx";
import { AiOutlineSearch } from "react-icons/ai";
import airpurifier from "../assets/airpurifier.png";
import financing from "../assets/financing.png";
import leaf from "../assets/leaf.png";
import payback from "../assets/payback.png";
import { Tooltip } from "@mui/material";
import { CiCircleInfo } from "react-icons/ci";

const Financing = () => {
  const { policyIncentives, sendPolicyIncentives, recommendationsMetrics } =
    useContext(GlobalContext);

  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = useState(UPDATED_INCENCTIVES);

  const searchInTable = () => {
    return UPDATED_INCENCTIVES?.filter(
      (incentivesData) =>
        incentivesData?.INCENTIVE_NAME?.toLowerCase().includes(
          search.toLowerCase()
        ) ||
        incentivesData?.SUB_TECHNOLOGY?.toLowerCase().includes(
          search.toLowerCase()
        ) ||
        incentivesData?.PROVIDER?.toLowerCase().includes(
          search.toLowerCase()
        ) ||
        incentivesData?.TECHNOLOGY?.toLowerCase().includes(search.toLowerCase())
    )?.map((item) => item);
  };

  useEffect(() => {
    setFilteredRows(searchInTable());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const columns = [
    { field: "INCENTIVE_ID", headerName: "INCENTIVE ID", width: 150 },
    {
      field: "INCENTIVE_NAME",
      headerName: "INCENTIVE NAME",
      width: 300,
      renderCell: (params) => {
        console.log("params11", params);
        return (
          <Tooltip title={params?.row?.INCENTIVE_NAME}>
            <span className="table-cell-trucate">
              {params.row?.INCENTIVE_NAME}
            </span>
          </Tooltip>
        );
      },
    },
    { field: "JURISDICTION", headerName: "JURISDICTION", width: 150 },
    {
      field: "TECHNOLOGY",
      headerName: "TECHNOLOGY",
      renderCell: (params) => {
        console.log("params11", params);
        return (
          <Tooltip title={params?.row?.TECHNOLOGY}>
            <span className="table-cell-trucate">{params.row?.TECHNOLOGY}</span>
          </Tooltip>
        );
      },
      width: 150,
    },
    { field: "SUB_TECHNOLOGY", headerName: "SUB TECHNOLOGY", width: 150 },
    {
      field: "INCENTIVE",
      headerName: "INCENTIVE",
      width: 150,
      renderCell: (params) => {
        console.log("params11", params);
        return (
          <Tooltip title={params?.row?.INCENTIVE}>
            <span className="table-cell-trucate">{params.row?.INCENTIVE}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "PROVIDER",
      headerName: "PROVIDER",
      width: 150,
      renderCell: (params) => {
        console.log("params11", params);
        return (
          <Tooltip title={params?.row?.PROVIDER}>
            <span className="table-cell-trucate">{params.row?.PROVIDER}</span>
          </Tooltip>
        );
      },
    },
    { field: "STATE", headerName: "STATE", width: 150 },
    { field: "COUNTY", headerName: "COUNTY", width: 150 },
    {
      field: "AMT_DOLLAR_PER_SQFT",
      headerName: "AMT DOLLAR PER SQFT",
      width: 150,
    },
    { field: "WEBSITE_LINK", headerName: "WEBSITE LINK", width: 150 },
    { field: "MAX_AMT", headerName: "MAX AMT", width: 150 },
    {
      field: "INCENTIVE_DOLLAR_VALUE",
      headerName: "INCENTIVE DOLLAR VALUE",
      width: 150,
    },
  ];

  const FINANCING_CARDS = [
    {
      icon: payback,
      title: "POLICY INCENTIVES",
      metric: "Up to $150K",
      policyIncentives: true,
    },
    {
      icon: airpurifier,
      title: "EMISSIONS REDUCED",
      metric: `${recommendationsMetrics?.emissions} tons CO2/yr`,
    },
    {
      icon: financing,
      title: "INVESTMENT",
      metric: `$${recommendationsMetrics?.investment}`,
    },
    {
      icon: leaf,
      title: "SAVINGS (25 years)",
      metric: `$${recommendationsMetrics?.savings}`,
    },
    {
      icon: payback,
      title: "PAYBACK",
      metric: `${
        recommendationsMetrics?.payback?.min +
        " - " +
        recommendationsMetrics?.payback?.max
      } yrs`,
    },
  ];

  if (!policyIncentives) {
    return (
      <ComponentCard
        style={{
          marginBottom: "16px",
          width: window?.innerWidth > 1350 ? "84%" : "76%",
          margin: window?.innerWidth > 1350 ? "0px 0px 0px auto" : "0px",
          overflowX: "hidden",
          position: "relative",
        }}
      >
        <Alignment
          padding="19px 32px"
          style={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
            borderBottom: "1px solid #CBD4D9",
          }}
        >
          <Font20PX style={{ color: "#343541" }}>Project Summary</Font20PX>
        </Alignment>
        <LayoutBox
          style={{
            alignItems: "center",
            margin: "27px 0px 59px",
            gap: "24px",
          }}
        >
          {/* <Alignment padding="0px 32px">
            <CheckButton
              style={{
                background: "#D7F3B9",
                border: "none",
                padding: "8px 10px",
              }}
            >
              <HiCheck
                style={{
                  color: "black",
                  fontSize: "24px",
                  marginTop: "2px",
                }}
              />
            </CheckButton>
          </Alignment> */}
          <Alignment padding="0px 32px">
            <MediumText
              style={{
                fontSize: "28px",
                lineHeight: "36.06px",
                color: "#343541",
              }}
            >
              Your request has been successfully submitted.
            </MediumText>
            <Alignment margin="8px 0px 0px">
              <LightFont style={{ color: "#343541" }}>
                Our team will get back to your shortly! Reach out to us at
                <span
                  style={{ color: "rgba(39, 80, 205, 1)", cursor: "pointer" }}
                >
                  &nbsp;contact@blueurbn.com
                </span>{" "}
                in case of urgent queries.
              </LightFont>
            </Alignment>
          </Alignment>
        </LayoutBox>
        <Alignment margin="32px 32px 0px">
          <Alignment>
            <MediumText
              style={{
                fontSize: "20px",
                lineHeight: "26.06px",
                color: "#343541",
                fontWeight: "600",
              }}
            >
              Your project overview
            </MediumText>
            <Alignment margin="24px 0px 0px">
              <LayoutBox style={{ gap: "12px", flexWrap: "wrap" }}>
                {FINANCING_CARDS?.map((content) => {
                  return <SolutionsCard content={content} />;
                })}
              </LayoutBox>
            </Alignment>
          </Alignment>
        </Alignment>
        <Alignment>
          <Alignment margin="56px 32px 24px">
            <MediumText style={{ fontSize: "20px", lineHeight: "26.06px" }}>
              Financing options that we support
            </MediumText>
          </Alignment>
          <Alignment padding="0px 32px">
            <table>
              <tr>
                {FINANCING_OPTIONS?.headers?.map((headerData) => {
                  return (
                    <>
                      {headerData?.includes("color_header") ? (
                        <Alignment
                          style={{
                            width: "6px",
                            background: "#DEE6FF",
                            height: "40px",
                            position: "relative",
                            top: "2px",
                            left: "8px",
                            borderRadius: "2px",
                          }}
                        ></Alignment>
                      ) : (
                        <StyledHeader>{headerData}</StyledHeader>
                      )}
                    </>
                  );
                })}
              </tr>

              {FINANCING_OPTIONS?.rowData?.map((data) => {
                return (
                  <tr>
                    <td style={{ padding: "19px 8px" }}>
                      <Alignment
                        style={{
                          width: "6px",
                          height: "40px",
                          borderRadius: "2px",
                          background: data.title?.includes(
                            "Energy as a Service"
                          )
                            ? "#7998F8"
                            : "#DEE6FF",
                        }}
                      ></Alignment>
                    </td>
                    <td style={{ padding: "19px 0px" }}>
                      <LayoutBox style={{ gap: "24px" }}>
                        <Alignment style={{ position: "relative" }}>
                          <SmallText
                            style={{
                              fontSize: "16px",
                              lineHeight: "20.85px",
                              margin: "0px 0px -14px",
                              fontWeight: "600",
                              display: "flex",
                              gap: "4px",
                              alignItems: "center",
                            }}
                          >
                            {data?.title?.split(", ")?.[0]}
                            <Alignment margin="4px 0px 0px">
                              <CiCircleInfo />
                            </Alignment>
                            {data?.title?.includes("Energy as a Service") ? (
                              <Alignment
                                style={{
                                  background: "#FEDC8F",
                                  padding: "2px 8px",
                                  borderRadius: "4px",
                                  marginLeft: "10px",
                                }}
                              >
                                <MediumText style={{ fontSize: "12px" }}>
                                  Recommended
                                </MediumText>
                              </Alignment>
                            ) : null}
                          </SmallText>
                          <br />
                          <LightFont style={{ fontWeight: "300" }}>
                            {data?.title?.split(", ")?.[1]}
                          </LightFont>
                        </Alignment>
                      </LayoutBox>
                    </td>
                    <td>{data?.own_equiment}</td>
                    <td>{data?.upfront_cost}</td>
                    <td>{data?.monthly_installment}</td>
                    <td>{data?.payback_period}</td>
                  </tr>
                );
              })}
            </table>
          </Alignment>
        </Alignment>{" "}
      </ComponentCard>
    );
  } else {
    return (
      <ComponentCard
        style={{
          height: "96vh",
          width: "84%",
          margin: "0px 0px 0px auto",
          overflowX: "hidden",
        }}
      >
        <Alignment>
          <LayoutBox
            style={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MediumText
              style={{
                fontSize: "20px",
                lineHeight: "26.06px",
                padding: "19px 32px",
              }}
            >
              Applicable policy incentives
            </MediumText>
            <StyledIcon
              style={{ cursor: "pointer", padding: "19px 32px" }}
              onClick={() => sendPolicyIncentives(false)}
            >
              <RxCross2 style={{ width: "24px", height: "24px" }} />
            </StyledIcon>
          </LayoutBox>
        </Alignment>

        <Alignment padding="0px 32px" style={{ maxWidth: "320px" }}>
          <>
            <InputWrapper>
              <IconContainer>
                <AiOutlineSearch />
              </IconContainer>
              <StyledInput
                type="text"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                placeholder="Search location"
              />
            </InputWrapper>
          </>
        </Alignment>
        <Alignment padding="24px 32px 0px" style={{ height: "60%" }}>
          <DataGrid
            getRowId={(row) => row?.id}
            rows={filteredRows}
            columns={columns}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0
                ? "Mui-even"
                : "Mui-odd"
            }
          />
        </Alignment>
      </ComponentCard>
    );
  }
};

export default Financing;

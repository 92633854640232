import React, { useContext, useState } from "react";
import {
  Alignment,
  CommonButton,
  ComponentCard,
  Font20PX,
  LayoutBox,
  LightFont,
  MediumText,
  NumberWrapper,
  StyledIcon,
  StyledTextBox,
} from "../styles/appstyles";
import { IconLabel } from "../components/componentsStyles";
import // BUILDING_ANALYSIS,
// computeCarbonEmissions,
// computePercentage,
// computeReduction,
"../constants";
import EnergyConsumption from "./EnergyConsumption";
import EnergyBill from "./EnergyBill";
import EmissionReduction from "./EmissionReduction";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";
import energyUsage from "../assets/energyUsage.png";
import { CiCircleInfo } from "react-icons/ci";
import { IoIosArrowRoundUp, IoIosArrowRoundDown } from "react-icons/io";
import { formatNumber } from "../constants";
import {
  FiZap,
  FiCloudDrizzle,
  FiFileText,
  FiCheckCircle,
} from "react-icons/fi";

const BuildingAnalysis = () => {
  const navigate = useNavigate();
  const [clickedIndex, setClickedIndex] = useState(0);
  const { sendBuildingAnalysisData, standardResponse } =
    useContext(GlobalContext);
  const fetchComponent = () => {
    if (clickedIndex === 0) {
      return (
        <Alignment
          style={{
            margin: "20px 0px 32px",
            borderRadius: "12px",
            border: "1px solid #D3D3D380",
            padding: "24px",
          }}
        >
          <EnergyConsumption />
        </Alignment>
      );
    } else if (clickedIndex === 1) {
      return (
        <Alignment
          style={{
            margin: "24px 0px",
            border: "1px solid #D3D3D380",
            padding: "24px",
            borderRadius: "12px",
          }}
        >
          <EmissionReduction />
        </Alignment>
      );
    } else if (clickedIndex === 2) {
      return (
        <Alignment
          style={{
            margin: "24px 0px",
            border: "1px solid #D3D3D380",
            padding: "24px",
            borderRadius: "12px",
          }}
        >
          <EnergyBill />
        </Alignment>
      );
    }
  };

  // const energySaved = JSON.parse(localStorage?.getItem("energySaved"));

  // const computePercentage = () => {
  //   const percentageDecrease =
  //     (energySaved?.totalEnergySaved / standardResponse?.[0]?.electricity) *
  //     100;
  //   return Math.round(percentageDecrease);
  // };

  const computePercentage = () => {
    let costEmissions = 0;
    const baselineSavings = standardResponse?.[0]?.electricity / 1000;
    const computedData = standardResponse?.map((data, index) => {
      if (index > 0) {
        const computeCostSavings =
          (standardResponse?.[0]?.electricity - data?.electricity) / 1000;
        return Math.round(computeCostSavings * 10) / 10;
      } else return 0;
    });

    computedData?.forEach((data) => (costEmissions = costEmissions + data));

    // const costSavings = Math.round(baselineSavings - costEmissions);

    const percentageSavings =
      Math.round((costEmissions / baselineSavings) * 100 * 10) / 10;
    return Math.round(percentageSavings * 10) / 10;
  };

  const computeReduction = () => {
    let costEmissions = 0;
    const baselineSavings = (standardResponse?.[0]?.electricity * 190) / 1000;
    const computedData = standardResponse?.map((data, index) => {
      if (index > 0) {
        const computeCostSavings =
          ((standardResponse?.[0]?.electricity - data?.electricity) * 190) /
          1000;
        return Math.round(computeCostSavings * 10) / 10;
      } else return 0;
    });

    computedData?.forEach((data) => (costEmissions = costEmissions + data));

    const costSavings = Math.round(
      (standardResponse?.[0]?.electricity * 190) / 1000 - costEmissions
    );

    const percentageSavings =
      Math.round((baselineSavings - costSavings) * 10) / 10;
    return Math.round((percentageSavings / baselineSavings) * 100 * 10) / 10;
  };

  const computeCarbonEmissions = () => {
    let sumOfAllEmissions = 0;
    const computedData = standardResponse?.map((data, index) => {
      if (index > 0) {
        // sum = sum + (data.electricity / 1000) * 0.364;
        const computeCarbonSaved =
          (standardResponse?.[0]?.electricity * 0.364 -
            data?.electricity * 0.364) /
          1000;
        return Math.round(computeCarbonSaved * 10) / 10;
      } else return 0;
    });

    computedData?.forEach(
      (data) => (sumOfAllEmissions = sumOfAllEmissions + data)
    );

    return {
      savings:
        Math.round(
          ((standardResponse?.[0]?.electricity * 0.364) / 1000 -
            sumOfAllEmissions) *
            10
        ) / 10,
      lifetime:
        Math.round(
          ((standardResponse?.[0]?.electricity * 0.364) / 1000 -
            sumOfAllEmissions) *
            25 *
            10
        ) / 10,
    };
  };

  const BUILDING_ANALYSIS = [
    {
      icon: <FiZap size="18px" />,
      title: "ANNUAL ENERGY CONSUMPTION",
      mainMetric:
        Math.round((standardResponse?.[0]?.electricity / 1000) * 10) / 10 +
          " MWh/yr" ?? -1,
      metrics: [`${computePercentage()}% savings annually`],
      arrowIcon: (
        <IoIosArrowRoundUp style={{ color: "#2750cd", fontSize: "18px" }} />
      ),
    },
    {
      icon: <FiCloudDrizzle size="18px" />,
      title: "EMISSIONS THIS YEAR",
      mainMetric: `${
        Math.round((standardResponse?.[0]?.electricity / 1000) * 0.364 * 10) /
        10
      } tons of CO2/yr`,
      metrics: [
        `Reduce ${computeCarbonEmissions()?.savings} tons of CO2/yr`,
        `${computeCarbonEmissions()?.lifetime} tons lifetime reductions`,
      ],
      arrowIcon: (
        <IoIosArrowRoundDown
          style={{
            color: "#2750cd",

            fontSize: "18px",
          }}
        />
      ),
    },
    {
      icon: <FiFileText size="18px" />,
      title: "ANNUAL ENERGY BILL",
      mainMetric: `$${formatNumber(
        Math.round((standardResponse?.[0]?.electricity / 1000) * 190)
      )}/yr`,
      metrics: [`${computeReduction()}% savings annually`],
      arrowIcon: <FiCheckCircle size="14px" style={{ color: "#2750cd" }} />,
    },
  ];

  return (
    <ComponentCard
      style={{
        width: window?.innerWidth > 1350 ? "84%" : "76%",
        margin: window?.innerWidth > 1350 ? "0px 0px 0px auto" : "0px",
        overflowX: "hidden",
        position: "relative",
      }}
    >
      <Alignment
        padding="19px 32px"
        style={{
          display: "flex",
          gap: "16px",
          alignItems: "center",
          // borderBottom: "1px solid #CBD4D9",
        }}
      >
        <NumberWrapper>
          <MediumText
            style={{ fontSize: "14px", color: "white", lineHeight: "18.24px" }}
          >
            2
          </MediumText>
        </NumberWrapper>
        <Font20PX style={{ color: "#343541" }}>Building Analysis</Font20PX>
      </Alignment>

      <LayoutBox
        style={{
          maxHeight: "80%",
          // borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          overflowY: "scroll",
        }}
      >
        <Alignment>
          <Alignment padding="24px 32px">
            <MediumText
              style={{
                fontSize: "28px",
                lineHeight: "42px",
              }}
            >
              Energy Usage:{" "}
              <img
                src={energyUsage}
                alt="energy_usage"
                style={{ position: "relative", top: "4px" }}
              />{" "}
              High
            </MediumText>
            <LightFont style={{ color: "#85868D", marginTop: "8px" }}>
              Lower 10th percentile compared to other buildings
            </LightFont>
          </Alignment>
          <Alignment padding="0px 32px 20px">
            {BUILDING_ANALYSIS?.map((building, index) => {
              return (
                <LayoutBox
                  style={{
                    padding: "24px 45px 24px 16px",
                    alignItems: "start",
                    gap: "16px",
                    cursor: "pointer",
                  }}
                  onClick={() => setClickedIndex(index)}
                  className={clickedIndex === index ? "active" : "inactive"}
                >
                  <StyledIcon>{building?.icon}</StyledIcon>
                  <StyledTextBox>
                    <div
                      style={{
                        display: "flex",
                        gap: "4px",
                        marginBottom: "8px",
                      }}
                    >
                      <IconLabel style={{ color: "#343541", opacity: "0.6" }}>
                        {building.title}
                      </IconLabel>
                      <>
                        <CiCircleInfo
                          style={{
                            position: "relative",
                            top: "1px",
                            width: "12px",
                            height: "12px",
                          }}
                        />
                      </>
                    </div>
                    <Alignment margin="0px 0px 4px">
                      <MediumText
                        style={{
                          fontSize: "24px",
                          lineHeight: "31.27px",
                          fontWeight: "600",
                        }}
                      >
                        {building?.mainMetric}
                      </MediumText>
                    </Alignment>
                    {building?.metrics?.map((data) => {
                      return (
                        <Alignment
                          margin="0px 0px 8px"
                          style={{
                            background: "white",
                            borderRadius: "12px",
                            padding: "8px",
                            border: "1px solid #EAEAEA",
                          }}
                        >
                          <LayoutBox style={{ gap: "8px" }}>
                            <Alignment
                              style={{ position: "relative", top: "1px" }}
                            >
                              {building?.arrowIcon}
                            </Alignment>

                            <LightFont
                              style={{
                                color: "#6885DC",
                                fontSize: "14px",
                                lineHeight: "20.85px",
                              }}
                            >
                              {data}
                            </LightFont>
                          </LayoutBox>
                        </Alignment>
                      );
                    })}
                  </StyledTextBox>
                </LayoutBox>
              );
            })}
          </Alignment>
        </Alignment>
        <Alignment padding="0px 0px 0px 32px">{fetchComponent()}</Alignment>
      </LayoutBox>
      {/* <Alignment
        margin="32px 32px"
        style={{
          textAlign: "right",
        }}
      >
        <CommonButton
          style={{ borderRadius: "12px" }}
          onClick={() => {
            sendBuildingAnalysisData(BUILDING_ANALYSIS);
            navigate("/recommendations");
          }}
        >
          Show Recommendations
        </CommonButton>
      </Alignment> */}
      <Alignment
        margin="1px 32px"
        style={{
          textAlign: "right",
          margin: "24px 24px",
          position: "absolute",
          bottom: "-10px",
          right: "0px",
          background: "white",
          width: "100%",
          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <CommonButton
          style={{ borderRadius: "12px", margin: "12px 12px 6px" }}
          onClick={() => {
            sendBuildingAnalysisData(BUILDING_ANALYSIS);
            navigate("/recommendations");
          }}
        >
          Show Recommendations
        </CommonButton>
      </Alignment>
    </ComponentCard>
  );
};

export default BuildingAnalysis;

import React from "react";
import { Alignment, Font20PX, LayoutBox, LightFont } from "../styles/appstyles";
import FloatingChart from "../components/FloatingChart";

const EmissionReduction = () => {
  const FLOATING_CHART_LABELS = {
    Solar: "#FED883",
    Insulation: "#A5E4FE",
    Controls: "#C2A5FE",
    Lighting: "#FEC5A5",
    HVAC: "#C5EE9C",
  };

  const fetchSize = () => {
    if (window?.innerWidth > 1400) {
      return "100%";
    } else if (window?.innerWidth < 1400 && window?.innerWidth > 1350) {
      return "550px";
    } else if (window?.innerWidth < 1350 && window?.innerWidth > 1300) {
      return "488px";
    } else if (window?.innerWidth > 1250 && window?.innerWidth < 1300) {
      return "440px";
    }
  };

  return (
    <Alignment
      style={{
        width: fetchSize(),
        position: "relative",
      }}
    >
      <Alignment margin="32px 0px 8px">
        <Font20PX
          style={{
            fontSize: window.innerWidth > 1000 ? "28px" : "20px",
            fontColor: "#343541",
          }}
        >
          Emission Reduction
        </Font20PX>
      </Alignment>

      <LightFont>
        Your energy bill reduction potential with energy solutions
      </LightFont>

      <Alignment
        style={{
          position: "absolute",
          right: "2px",
          top: "-50px",
          border: "1px solid #CBD4D9",
          padding: "4px",
          borderRadius: "12px",
        }}
      >
        {Object?.entries(FLOATING_CHART_LABELS)?.map(([key, value], index) => {
          return (
            <LayoutBox
              key={index}
              style={{
                alignItems: "center",
                gap: "10px",
                margin: index !== 4 ? "0px 0px 6px" : "0px",
              }}
            >
              <Alignment
                style={{
                  background: value,
                  height: "12px",
                  width: "12px",
                  marginTop: "4px",
                }}
              ></Alignment>
              <Alignment style={{ fontSize: "12px" }}>{key}</Alignment>
            </LayoutBox>
          );
        })}
      </Alignment>

      <Alignment margin={window.innerWidth < 1399 ? "86px 0px 0px" : "0px"}>
        <FloatingChart />
      </Alignment>
    </Alignment>
  );
};

export default EmissionReduction;

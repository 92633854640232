import React, { useState } from "react";
import {
  Alignment,
  Font20PX,
  LayoutBox,
  LightFont,
  SmallText,
  StyledDiv,
} from "../styles/appstyles";
import CostSavingChart from "../components/CostSavingChart";
import { COST_SAVING_GRAPH } from "../constants";

const EnergyBill = () => {
  const [clickedIndex, setClickedIndex] = useState(0);

  const fetchSize = () => {
    if (window?.innerWidth > 1400) {
      return "100%";
    } else if (window?.innerWidth < 1400 && window?.innerWidth > 1350) {
      return "550px";
    } else if (window?.innerWidth < 1350 && window?.innerWidth > 1300) {
      return "488px";
    } else if (window?.innerWidth > 1250 && window?.innerWidth < 1300) {
      return "440px";
    }
  };

  return (
    <Alignment style={{ width: fetchSize() }}>
      <LayoutBox
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Alignment>
          <Alignment margin="32px 0px 8px">
            <Font20PX
              style={{
                fontSize: window.innerWidth > 1000 ? "28px" : "20px",
                fontColor: "#343541",
              }}
            >
              Cost Savings
            </Font20PX>
          </Alignment>

          <LightFont>Reduction potential of your energy bills</LightFont>
        </Alignment>
        <Alignment style={{ position: "relative", top: "10px", right: "12px" }}>
          <LayoutBox
            style={{
              background: "rgb(244, 247, 251)",
              maxWidth: "317px",
              padding: "4px",
              borderRadius: "12px",
            }}
          >
            {COST_SAVING_GRAPH?.map((metric, index) => {
              return (
                <StyledDiv
                  onClick={() => setClickedIndex(index)}
                  className={clickedIndex === index ? "active" : "inactive"}
                >
                  <Alignment margin="15px 16px" style={{ cursor: "pointer" }}>
                    <Alignment>
                      <SmallText
                        style={{
                          fontSize: "14px",
                          lineHeight: "18.24px",
                        }}
                      >
                        {metric?.title}
                      </SmallText>
                    </Alignment>
                  </Alignment>
                </StyledDiv>
              );
            })}
          </LayoutBox>
        </Alignment>
      </LayoutBox>

      <Alignment>
        <CostSavingChart />
      </Alignment>
    </Alignment>
  );
};

export default EnergyBill;

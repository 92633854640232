import styled from "styled-components";

export const AppWrapper = styled.div`
  background: #f3f6fc;
  display: flex;
  gap: 8px;
  height: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

export const LogoContainer = styled.div`
  img {
    width: 37.8px;
    height: 37.8px;
  }
`;

export const Container = styled.div``;

export const MediumText = styled.div`
  font-size: 18.58px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 700;
  line-height: 24.21px;
  color: #343541;
`;

export const LayoutBox = styled.div`
  display: flex;

  &.active {
    background: #f8f8f8;
    border-radius: 12px;
  }
  &.inactive {
    background: #ffffff;
  }
`;

export const Alignment = styled.div(({ margin, padding }) => ({
  margin,
  padding,
}));

export const SmallText = styled.div`
  font-size: 16px;
  font-family: "Plus Jakarta Sans", sans-serif;
  line-height: 15.64px;
  color: #343541;
`;

export const TabContainer = styled.div`
  &.active {
    background: rgba(255, 255, 255, 0.5);
  }
  &.deactive {
    opacity: 0.7;
  }
`;

export const ComponentCard = styled.div(() => ({
  background: "white",
  borderRadius: "12px 0 0 12px",
  height: window?.innerHeight > 720 ? "100vh" : "100vh",
}));

export const Font20PX = styled.div`
  font-size: 20px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  line-height: 26.06px;
`;

export const LightFont = styled.div`
  font-size: 16px;
  font-family: "Plus Jakarta Sans", sans-serif;
  line-height: 24px;
  font-weight: 400;
`;

export const NumberWrapper = styled.div`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 5px;
  background: #343541;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
`;

export const CommonButton = styled.button`
  padding: 14px 12px;
  background: #2750cd;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.85px;
  border-radius: 12px;
  border: 2px solid #2750cd;

  &.disabled {
    opacity: 0.5;
  }

  &.enabled {
    opacity: 1;
  }
`;

export const StyledIcon = styled.div`
  img {
    height: 24px;
    width: 24px;
  }

  &.align-image img {
    height: 20px;
    width: 15px;
  }

  &.align-image {
    margin-right: 10px;
  }
`;

export const StyledTextBox = styled.div``;

export const StyledDiv = styled.div`
  &.active {
    background: #ffffff;
    border-radius: 12px;
    transition: all 0.5s ease-in;
  }
  &.inactive {
    background: rgb(244, 247, 251);
    border-radius: 12px;
  }

  &.activate-tabs {
    opacity: 1;
  }

  &.deactivate-tabs {
    opacity: 0.6;
  }
`;

export const CheckButton = styled.button`
  border-radius: 50%;
  padding: 2px 5px;
  cursor: pointer;

  &.active {
    background: black;
  }
  &.inactive {
    background: transparent;
    border: 2px solid rgba(28, 27, 31, 0.4);
  }
`;

export const StyledHeader = styled.th`
  font-size: 12px;
  color: rgba(52, 53, 65, 0.8);
  font-family: HK-Grotesk-Bold;
  padding: 16px 0px;
`;

export const EditButton = styled.button`
  background: transparent;
  padding: 4px 8px;
  border: 1px solid grey;
  border-radius: 3px;
  font-family: HK-Grotesk-Light;
  font-size: 12px;
  &:hover {
    background: rgb(39, 80, 205);
    color: white;
    cursor: pointer;
  }
`;

export const StyledCard = styled.div`
  background: white;
  padding: 16px;
  border-radius: 12px;
`;

import React, { useContext, useEffect, useRef, useState } from "react";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
// import { SmallText } from "../styles/appstyles";
import {
  IconContainer,
  IconLabel,
  InputWrapper,
  StyledInput,
  // UnitContainer,
} from "./componentsStyles";
import { CiCircleInfo } from "react-icons/ci";
import { FiSearch } from "react-icons/fi";

// import Input from "./Input";
import { GlobalContext } from "../context/GlobalContext";
const libraries = ["places"];
const AddressSuggestionInput = ({ inputValue, handleInputChange }) => {
  const inputRef = useRef();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_API_SECRET_KEY,
    libraries,
  });

  const { buildingDetails, sendCreatedBuilding, sendBuildingDetails } =
    useContext(GlobalContext);

  const [addressValue, setAddressValue] = useState(
    buildingDetails?.address ?? ""
  );

  useEffect(() => {
    handleInputChange(addressValue, "address");
  }, [addressValue, handleInputChange]);

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      // sendPlacesDetails(place.formatted_address);
      sendBuildingDetails(inputValue);
      setAddressValue(place.formatted_address);
      sendCreatedBuilding({
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      });

      console.log(place.formatted_address);
      console.log(place.geometry.location.lat());
      console.log(place.geometry.location.lng());
    }
  };

  console.log("handlePlace", addressValue);

  return (
    isLoaded && (
      <StandaloneSearchBox
        onLoad={(ref) => (inputRef.current = ref)}
        onPlacesChanged={handlePlaceChanged}
      >
        <>
          <div style={{ display: "flex", gap: "6px", marginBottom: "8px" }}>
            <IconLabel style={{ color: "#343541", opacity: "0.6" }}>
              ADDRESS
            </IconLabel>
            <>
              <CiCircleInfo style={{ position: "relative", bottom: "1px" }} />
            </>
          </div>
          <InputWrapper>
            <IconContainer>
              {/* <img src={search} alt="icon" /> */}
              <FiSearch />
            </IconContainer>
            <StyledInput
              type="text"
              placeholder="Enter your building address"
              onChange={(e) => {
                setAddressValue(e.target.value);
              }}
              value={addressValue}
            />
          </InputWrapper>
        </>
      </StandaloneSearchBox>
    )
  );
};

export default AddressSuggestionInput;

import React, { useContext } from "react";
import logo from "../assets/logo.png";
import {
  Alignment,
  LayoutBox,
  LightFont,
  LogoContainer,
  MediumText,
  SmallText,
  TabContainer,
  Wrapper,
  EditButton,
  StyledDiv,
  Container,
} from "../styles/appstyles";
import { TABS } from "../constants";
import { GlobalContext } from "../context/GlobalContext";
import { useNavigate } from "react-router-dom";

const TabSection = () => {
  const { sendIndex, standardResponse } = useContext(GlobalContext);

  const { buildingDetails, buildingAnalysisData, recommendationsData } =
    useContext(GlobalContext);
  const navigate = useNavigate();

  const computeReduction = () => {
    let costEmissions = 0;

    const computedData = standardResponse?.map((data, index) => {
      if (index > 0) {
        const computeCostSavings =
          ((standardResponse?.[0]?.electricity - data?.electricity) * 190) /
          1000;
        return Math.round(computeCostSavings * 10) / 10;
      } else return 0;
    });

    computedData?.forEach((data) => (costEmissions = costEmissions + data));

    const costSavings = Math.round(
      (standardResponse?.[0]?.electricity * 190) / 1000 - costEmissions
    );
    return Math.round(costSavings * 10) / 10;
  };

  const checkToRender = (index) => {
    if (index === 0 && buildingDetails?.address?.length > 0) {
      return true;
    } else if (index === 1 && buildingAnalysisData) {
      return true;
    } else if (index === 2 && recommendationsData?.recommendations) {
      return true;
    } else return false;
  };

  // const energySaved = JSON.parse(localStorage?.getItem("energySaved"));

  // const computePercentage = () => {
  //   const percentageDecrease =
  //     (energySaved?.totalEnergySaved / standardResponse?.[0]?.electricity) *
  //     100;
  //   return Math.round(percentageDecrease);
  // };

  const computePercentage = () => {
    let costEmissions = 0;

    const computedData = standardResponse?.map((data, index) => {
      if (index > 0) {
        const computeCostSavings =
          (standardResponse?.[0]?.electricity - data?.electricity) / 1000;
        return Math.round(computeCostSavings * 10) / 10;
      } else return 0;
    });

    computedData?.forEach((data) => (costEmissions = costEmissions + data));

    return Math.round(costEmissions * 10) / 10;
  };

  const fetchDetails = (index) => {
    console.log("buildingDetails", buildingDetails);
    if (index === 0 && buildingDetails?.address?.length > 0) {
      return (
        <Alignment>
          {" "}
          <LightFont style={{ fontSize: "14px", margin: " 0px 0px 0px 36px" }}>
            {buildingDetails?.address}
          </LightFont>
          <Alignment margin="8px 0px 0px -4px">
            <EditButton style={{ margin: " 0px 0px 0px 38px" }}>
              Edit
            </EditButton>
          </Alignment>
        </Alignment>
      );
    }

    if (index === 1 && buildingAnalysisData) {
      return (
        <Alignment>
          {" "}
          <LightFont style={{ fontSize: "14px", margin: "0px 0px 0px 36px" }}>
            Save up to{" "}
            {Math.round(
              (standardResponse?.[0]?.electricity / 1000) * 0.364 * 10
            ) / 10}
            {"T of CO2/yr"}
            {","} {"$"}
            {computeReduction()} {","} {computePercentage()}MWh/yr
          </LightFont>
        </Alignment>
      );
    }

    if (index === 2 && recommendationsData?.recommendations) {
      return (
        <Alignment style={{ margin: " 0px 0px 0px 38px" }}>
          {" "}
          <LightFont>{recommendationsData?.recommendations}</LightFont>
          <Alignment margin="8px 0px 0px">
            <EditButton>Edit</EditButton>
          </Alignment>
        </Alignment>
      );
    }
  };

  console.log("buildingDetails", buildingDetails);
  return (
    <Wrapper>
      <Alignment margin={window.innerWidth > "1000" ? "16px 24px 0px" : "0px"}>
        <Alignment margin={window.innerWidth > "1000" ? "0px 0px 20px" : "0px"}>
          <LayoutBox
            style={{
              gap: "12px",
              alignItems: "center",
              background: "#2750CD",
              padding: "12px 24px",
              borderRadius: "12px",
            }}
          >
            <LogoContainer>
              <img src={logo} alt="blueurbn-logo" />
            </LogoContainer>
            <div>
              <MediumText style={{ fontSize: "20px", color: "#FFFFFF" }}>
                BlueUrbn
              </MediumText>
            </div>
          </LayoutBox>
        </Alignment>

        {Object.entries?.(TABS)?.map(([key, value], index) => {
          return (
            <TabContainer
              className={
                window.location.pathname === value?.name ? "active" : "deactive"
              }
              onClick={() => {
                sendIndex(index);
                if (index === 0) {
                  navigate("/");
                } else if (index === 1 && buildingDetails?.address) {
                  navigate("/building-analysis");
                } else if (index === 2 && buildingDetails?.address) {
                  navigate("/recommendations");
                } else if (index === 3 && buildingDetails?.address) {
                  navigate("/project-summary");
                }
              }}
              style={{ borderRadius: "12px" }}
            >
              <StyledDiv
                className={
                  !buildingDetails && index !== 0
                    ? "deactivate-tabs"
                    : "activate-tabs"
                }
              >
                <Alignment
                  padding={window.innerWidth > "1000" ? "24px 24px" : "0px"}
                  style={{ cursor: "pointer" }}
                >
                  <LayoutBox
                    style={{
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <Container>{value?.icon}</Container>
                    <Alignment margin="0px 0px 4px">
                      <SmallText
                        style={{
                          color: "#343541",
                          fontWeight:
                            window.location.pathname === value?.name
                              ? "600"
                              : null,
                        }}
                      >
                        {key}
                      </SmallText>
                    </Alignment>
                  </LayoutBox>
                  {checkToRender(index) ? (
                    <LayoutBox style={{ alignItems: "center" }}>
                      <Container style={{ display: "none" }}>
                        <img
                          src={value?.icon}
                          alt="blueurbn-logo"
                          style={{ color: "#343541" }}
                        />
                      </Container>
                      <Alignment>{fetchDetails(index)}</Alignment>
                    </LayoutBox>
                  ) : null}
                </Alignment>
              </StyledDiv>
            </TabContainer>
          );
        })}
      </Alignment>
    </Wrapper>
  );
};

export default TabSection;

import React from "react";
import { Alignment, Font20PX, LightFont } from "../styles/appstyles";
import BarChart from "../components/BarChart";

const EnergyConsumption = () => {
  const fetchSize = () => {
    if (window?.innerWidth > 1400) {
      return "100%";
    } else if (window?.innerWidth < 1400 && window?.innerWidth > 1350) {
      return "550px";
    } else if (window?.innerWidth < 1350 && window?.innerWidth > 1300) {
      return "488px";
    } else if (window?.innerWidth > 1250 && window?.innerWidth < 1300) {
      return "440px";
    }
  };

  return (
    <Alignment
      style={{
        width: fetchSize(),
        position: "relative",
      }}
    >
      <Alignment margin="32px 0px 8px">
        <Font20PX
          style={{
            fontSize: window.innerWidth > 1000 ? "28px" : "20px",
            fontColor: "#343541",
          }}
        >
          Energy Consumption
        </Font20PX>
      </Alignment>

      <LightFont>
        Your energy bill reduction potential with energy solutions
      </LightFont>
      <Alignment>
        <BarChart />
      </Alignment>
    </Alignment>
  );
};

export default EnergyConsumption;

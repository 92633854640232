import React, { useContext, useEffect, useState } from "react";
import {
  Alignment,
  CommonButton,
  ComponentCard,
  Font20PX,
  LayoutBox,
  LightFont,
  MediumText,
  NumberWrapper,
  SmallText,
  // StyledDiv,
  // CheckButton,
  StyledHeader,
} from "../styles/appstyles";
import {
  formatNumber,
  // FINANCING_OPTIONS,
  // RECOMMENDATIONS_FILTER,
} from "../constants";
import SolutionsCard from "../components/SolutionsCard";

import { FiSun, FiWind, FiSliders, FiThermometer } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";
import airpurifier from "../assets/airpurifier.png";
import financing from "../assets/financing.png";
import leaf from "../assets/leaf.png";
import payback from "../assets/payback.png";
import { Checkbox } from "@mui/material";
import { MdOutlineLightbulb } from "react-icons/md";

const Recommendations = () => {
  // const [clickedIndex, setClickedIndex] = useState(0);
  const {
    sendRecommendationsData,
    recommendationsData,
    standardResponse,
    sendRecommendationsMetrics,
    newStructure,
  } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [toggle, setToggle] = useState({
    solar: recommendationsData?.recommendationsData?.solar ?? true,
    insulation: recommendationsData?.recommendationsData?.insulation ?? true,
    controls: recommendationsData?.recommendationsData?.controls ?? true,
    lighting: recommendationsData?.recommendationsData?.lighting ?? true,
    hvac: recommendationsData?.recommendationsData?.hvac ?? true,
  });

  const [emissionsReduced, setEmissionsReduced] = useState(0);

  // const checkForState = (energyTitle) => {
  //   if (toggle?.[energyTitle]) {
  //     return "active";
  //   } else {
  //     return "inactive";
  //   }
  // };

  const computeEmissionsReduced = () => {
    const baseline = standardResponse?.[0]?.electricity;
    let sumOfCarbonEmissions = 0;
    const filterDesignType = standardResponse?.filter(
      (item) => toggle[item?.design_type]
    );
    sumOfCarbonEmissions = 0;

    const emissionsReduced = filterDesignType?.map((data, index) => {
      return (baseline - data.electricity) / 1000;
    });

    emissionsReduced?.forEach(
      (data) => (sumOfCarbonEmissions = sumOfCarbonEmissions + data)
    );

    return sumOfCarbonEmissions * 0.364;
  };

  const computeInvestment = () => {
    let sumOfInvestments = 0;
    const filterDesignType = standardResponse?.filter(
      (item) => toggle[item?.design_type]
    );
    filterDesignType?.forEach(
      (data) => (sumOfInvestments = sumOfInvestments + data.cost)
    );

    return formatNumber(Math.round(sumOfInvestments * 10) / 10);
  };

  const computeCostSavings = () => {
    let costEmissions = 0;
    const filterDesignType = standardResponse?.filter(
      (item) => toggle[item?.design_type]
    );
    const computedData = filterDesignType?.map((data, index) => {
      if (index > 0) {
        const computeCostSavings =
          ((standardResponse?.[0]?.electricity - data?.electricity) * 190) /
          1000;
        return Math.round(computeCostSavings * 10) / 10;
      } else return 0;
    });

    computedData?.forEach((data) => (costEmissions = costEmissions + data));
    return formatNumber(Math.round(costEmissions * 25 * 10) / 10);
  };

  const computePaybackForTable = (design_type) => {
    const savings =
      ((newStructure?.["baseline"]?.electricity -
        newStructure?.[design_type]?.electricity) *
        190) /
      1000;
    const paybackValue =
      Math.round((newStructure?.[design_type]?.cost / savings) * 10) / 10;

    if (paybackValue > 20) {
      return "-";
    } else return `${paybackValue} years`;
  };

  const computePayback = () => {
    const filterDesignType = standardResponse?.filter(
      (item) =>
        toggle[item?.design_type] &&
        item?.design_type.toLowerCase() !== "insulation"
    );

    if (filterDesignType?.length === 0) {
      return {
        min: 0,
        max: 0,
      };
    }

    const computedData = filterDesignType
      ?.map((data) => {
        const computeCostSavings =
          ((standardResponse?.[0]?.electricity - data?.electricity) * 190) /
          1000;

        return Math.round((data.cost / computeCostSavings) * 10) / 10;
      })
      ?.filter((data) => data < 20);

    return {
      min: Math.min.apply(Math, computedData),
      max: Math.max.apply(Math, computedData),
    };
  };

  useEffect(() => {
    const result = computeEmissionsReduced();
    setEmissionsReduced(Math.round(result * 10) / 10);
    const payback = computePayback();
    const investment = computeInvestment();
    const savings = computeCostSavings();
    sendRecommendationsMetrics(
      Math.round(result * 10) / 10,
      payback,
      investment,
      savings
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle]);

  const RECOMMENDATIONS_CARDS = [
    {
      icon: airpurifier,
      title: "EMISSIONS REDUCED",
      metric: `${emissionsReduced} tons CO2/yr`,
    },
    {
      icon: financing,
      title: "INVESTMENT",
      metric: `$${computeInvestment()}`,
    },
    {
      icon: leaf,
      title: "SAVINGS (25 years)",
      metric: `$${computeCostSavings()}`,
    },
    {
      icon: payback,
      title: "PAYBACK",
      metric: `${computePayback()?.min + " - " + computePayback()?.max} yrs`,
    },
  ];

  const ENERGY_SOLUTIONS = {
    headers: [
      "SELECT",
      "color_header",
      "ENERGY MODEL",
      "INVESTMENT",
      "SAVINGS",
      "EMISSION",
      "PAYBACK",
    ],
    rowData: [
      {
        icon: <FiSun />,
        color: "#FEDC8F",
        title: "Solar",
        metrics: {
          Investment: `$ ${
            Math.round(newStructure?.["solar"]?.cost * 10) / 10
          }`,
          "Savings(25Yr)": `$ ${
            Math.round(
              ((newStructure?.["baseline"]?.electricity -
                newStructure?.["solar"]?.electricity) /
                1000) *
                190 *
                25 *
                10
            ) / 10
          }`,
          "Emissions Reductions": `${
            Math.round(
              ((newStructure?.["baseline"]?.electricity -
                newStructure?.["solar"]?.electricity) /
                1000) *
                0.364 *
                10
            ) / 10
          } tons of CO2/yr`,
          Payback: `${computePaybackForTable("solar")}`,
        },
      },
      {
        icon: <FiWind />,
        color: "#AEE7FE",
        title: "Insulation",
        metrics: {
          Investment: `$ ${
            Math.round(newStructure?.["insulation"]?.cost * 10) / 10
          }`,
          "Savings(25Yr)": `$ ${
            Math.round(
              ((newStructure?.["baseline"]?.electricity -
                newStructure?.["insulation"]?.electricity) /
                1000) *
                190 *
                25 *
                10
            ) / 10
          }`,
          "Emissions Reductions": `${
            Math.round(
              ((newStructure?.["baseline"]?.electricity -
                newStructure?.["insulation"]?.electricity) /
                1000) *
                0.364 *
                10
            ) / 10
          } tons of CO2/yr`,
          Payback: `${computePaybackForTable("insulation")}`,
        },
      },
      {
        icon: <FiSliders />,
        color: "#D4BFFF",
        title: "Controls",
        metrics: {
          Investment: `$ ${
            Math.round(newStructure?.["controls"]?.cost * 10) / 10
          }`,
          "Savings(25Yr)": `$ ${
            Math.round(
              ((newStructure?.["baseline"]?.electricity -
                newStructure?.["controls"]?.electricity) /
                1000) *
                190 *
                25 *
                10
            ) / 10
          }`,
          "Emissions Reductions": `${
            Math.round(
              ((newStructure?.["baseline"]?.electricity -
                newStructure?.["controls"]?.electricity) /
                1000) *
                0.364 *
                10
            ) / 10
          } tons of CO2/yr`,
          Payback: `${computePaybackForTable("controls")}`,
        },
      },
      {
        // icon: bulb,
        color: "#D9D9D9",
        title: "Lighting",
        metrics: {
          Investment: `$ ${
            Math.round(
              (newStructure?.["lighting"]?.cost + Number.EPSILON) * 10
            ) / 10
          }`,
          "Savings(25Yr)": `$ ${
            Math.round(
              ((newStructure?.["baseline"]?.electricity -
                newStructure?.["lighting"]?.electricity) /
                1000) *
                190 *
                25 *
                10
            ) / 10
          }`,
          "Emissions Reductions": `${
            Math.round(
              ((newStructure?.["baseline"]?.electricity -
                newStructure?.["lighting"]?.electricity) /
                1000) *
                0.364 *
                10
            ) / 10
          } tons of CO2/yr`,
          Payback: `${computePaybackForTable("lighting")}`,
        },
        icon: <MdOutlineLightbulb />,
      },
      {
        icon: <FiThermometer />,
        color: "#D7F3B9",
        title: "HVAC",
        metrics: {
          Investment: `$ ${
            Math.round((newStructure?.["hvac"]?.cost + Number.EPSILON) * 100) /
            100
          }`,
          "Savings(25Yr)": `$ ${
            Math.round(
              ((newStructure?.["baseline"]?.electricity -
                newStructure?.["hvac"]?.electricity) /
                1000) *
                190 *
                25 *
                10
            ) / 10
          }`,
          "Emissions Reductions": `${
            Math.round(
              ((newStructure?.["baseline"]?.electricity -
                newStructure?.["hvac"]?.electricity) /
                1000) *
                0.364 *
                10
            ) / 10
          } tons of CO2/yr`,
          Payback: `${computePaybackForTable("hvac")}`,
        },
      },
    ],
  };

  return (
    <ComponentCard
      style={{
        marginBottom: "16px",
        width: window?.innerWidth > 1350 ? "84%" : "76%",
        margin: window?.innerWidth > 1350 ? "0px 0px 0px auto" : "0px",
        overflowX: "hidden",
        position: "relative",
      }}
    >
      <Alignment>
        <Alignment
          padding="19px 32px"
          style={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
            borderBottom: "1px solid #CBD4D9",
          }}
        >
          <NumberWrapper>
            <MediumText
              style={{
                fontSize: "14px",
                lineHeight: "18.24px",
                color: "white",
              }}
            >
              3
            </MediumText>
          </NumberWrapper>
          <Font20PX style={{ color: "#343541" }}>
            Energy solution recommendations
          </Font20PX>
        </Alignment>

        <Alignment margin="32px 32px 0px">
          <Alignment>
            <MediumText
              style={{
                fontSize: "20px",
                lineHeight: "26.06px",
                color: "#343541",
              }}
            >
              Impact of your energy solutions
            </MediumText>
            <Alignment margin="24px 0px 0px">
              <LayoutBox style={{ gap: "24px" }}>
                {RECOMMENDATIONS_CARDS?.map((content) => {
                  return <SolutionsCard content={content} />;
                })}
              </LayoutBox>
            </Alignment>
            <LayoutBox
              style={{
                justifyContent: "space-between",
                margin: "46px 32px 24px 0px",
              }}
            >
              <Alignment>
                <Alignment>
                  <MediumText
                    style={{ fontSize: "20px", lineHeight: "26.06px" }}
                  >
                    Confirm your energy solutions
                  </MediumText>
                </Alignment>
                <Alignment>
                  <LightFont>
                    You will be shown financing options in the next steps based
                    on your choice
                  </LightFont>
                </Alignment>
              </Alignment>
              <Alignment>
                {/* <LayoutBox style={{ alignItems: "center" }}>
                <SmallText
                  style={{
                    fontSize: "14px",
                    lineHeight: "18.24px",
                    marginRight: "16px",
                  }}
                >
                  Sort By
                </SmallText>
                <LayoutBox
                  style={{
                    background: "rgb(244, 247, 251)",
                    maxWidth: "317px",
                    padding: "4px",
                    borderRadius: "12px",
                  }}
                >
                  {RECOMMENDATIONS_FILTER?.map((metric, index) => {
                    return (
                      <StyledDiv
                        onClick={() => setClickedIndex(index)}
                        className={
                          clickedIndex === index ? "active" : "inactive"
                        }
                      >
                        <Alignment
                          margin="15px 16px"
                          style={{ cursor: "pointer" }}
                        >
                          <LayoutBox style={{ gap: "8px" }}>
                            <Alignment>
                              <img
                                src={metric?.icon}
                                alt={metric?.title}
                                style={{ height: "16px", width: "16px" }}
                              />
                            </Alignment>
                            <Alignment>
                              <SmallText
                                style={{
                                  fontSize: "14px",
                                  lineHeight: "18.24px",
                                }}
                              >
                                {metric?.title}
                              </SmallText>
                            </Alignment>
                          </LayoutBox>
                        </Alignment>
                      </StyledDiv>
                    );
                  })}
                </LayoutBox>
              </LayoutBox> */}
              </Alignment>
            </LayoutBox>
          </Alignment>
          {/* <Alignment>
          <LayoutBox
            style={{
              gap: "8px",
              alignItems: "baseline",
              overflowX: window?.innerWidth < 1400 ? "scroll" : "none",
            }}
          >
            {ENERGY_SOLUTIONS?.map((energySolution, index) => {
              return (
                <Alignment
                  padding="8px 12px 24px"
                  style={{
                    border: `1px solid ${
                      energySolution?.color === "white"
                        ? "rgba(0, 0, 0, 0.12)"
                        : energySolution?.color
                    }`,
                    marginTop: "auto",
                    borderBottom: "0px",
                    background: energySolution?.color,
                    borderRadius: "12px",
                  }}
                >
                  <Alignment style={{ position: "relative", left: "138px" }}>
                    <CheckButton
                      onClick={() => {
                        setToggle((prev) => ({
                          ...prev,
                          [energySolution?.title.toLowerCase()]:
                            !toggle?.[energySolution?.title.toLowerCase()],
                        }));
                      }}
                      className={checkForState(
                        energySolution?.title.toLowerCase()
                      )}
                    >
                      <HiCheck
                        style={{
                          color: energySolution?.color,
                          fontSize: "24px",
                          marginTop: "2px",
                        }}
                      />
                    </CheckButton>
                  </Alignment>
                  <Alignment
                    style={{
                      borderRadius: "50%",
                      background: energySolution?.color,
                    }}
                  >
                    <img
                      src={energySolution?.icon}
                      alt={energySolution?.title}
                    />
                  </Alignment>
                  <Alignment margin={`12px 0px ${108 - index * 24}px`}>
                    <MediumText
                      style={{ fontSize: "24px", lineHeight: "31.27px" }}
                    >
                      {energySolution?.title}
                    </MediumText>
                  </Alignment>
                  <Alignment margin="0px 0px 0px">
                    {Object.entries(energySolution?.metrics)?.map(
                      ([key, value], index) => {
                        return (
                          <LayoutBox
                            style={{
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: "16px",
                            }}
                          >
                            <Alignment padding="0px 42px 0px 0px">
                              <SmallText
                                style={{
                                  fontSize: "14px",
                                  lineHeight: "18.24px",
                                  opacity: "0.5",
                                }}
                              >
                                {key}
                              </SmallText>
                            </Alignment>

                            <Alignment>
                              <MediumText
                                style={{
                                  fontSize: "14px",
                                  lineHeight: "18.24px",
                                }}
                              >
                                {value}
                              </MediumText>
                            </Alignment>
                          </LayoutBox>
                        );
                      }
                    )}
                  </Alignment>
                </Alignment>
              );
            })}
          </LayoutBox>
        </Alignment> */}
          <Alignment
            padding="0px 32px 0px"
            style={{
              // overflowY: window?.innerHeight < 730 ? "scroll" : null,
              margin: "0px 0px 100px",
            }}
          >
            <table>
              <tr>
                {ENERGY_SOLUTIONS?.headers?.map((headerData) => {
                  if (headerData?.includes("color_header")) {
                    return (
                      <Alignment
                        style={{
                          width: "6px",
                          background: "#E9E9E9",
                          height: "20px",
                          position: "relative",
                          top: "12px",
                          left: "8px",
                        }}
                      ></Alignment>
                    );
                  } else {
                    return (
                      <StyledHeader
                        style={{
                          position: "relative",
                          left: headerData === "SELECT" ? "10px" : "0px",
                        }}
                      >
                        {headerData}
                      </StyledHeader>
                    );
                  }
                  // <>
                  //   {headerData?.includes("color_header") ? (
                  //     <Alignment
                  //       style={{
                  //         width: "6px",
                  //         background: "#E9E9E9",
                  //         height: "20px",
                  //         position: "relative",
                  //         top: "12px",
                  //         left: "8px",
                  //       }}
                  //     ></Alignment>
                  //   ) : (
                  //     <StyledHeader>{headerData}</StyledHeader>
                  //   )}
                  // </>
                  // );
                })}
              </tr>

              {ENERGY_SOLUTIONS?.rowData?.map((data) => {
                return (
                  <tr>
                    <td>
                      <Checkbox
                        checked={toggle?.[data?.title.toLowerCase()]}
                        onChange={() =>
                          setToggle((prev) => ({
                            ...prev,
                            [data?.title.toLowerCase()]:
                              !toggle?.[data?.title.toLowerCase()],
                          }))
                        }
                      />
                    </td>
                    <td>
                      <Alignment
                        style={{
                          width: "6px",
                          background: data.color,
                          height: "40px",
                        }}
                      ></Alignment>
                    </td>
                    <td style={{ padding: "19px 0px" }}>
                      <LayoutBox style={{ gap: "24px" }}>
                        <Alignment>{data?.icon}</Alignment>
                        <Alignment>
                          <SmallText
                            style={{
                              fontSize: "16px",
                              lineHeight: "20.85px",
                              margin: "0px 0px -14px",
                            }}
                          >
                            {data?.title?.split(", ")?.[0]}
                          </SmallText>
                          <br />
                          <LightFont>{data?.title?.split(", ")?.[1]}</LightFont>
                        </Alignment>
                      </LayoutBox>
                    </td>
                    <td>{data?.metrics?.Investment}</td>
                    <td>{data?.metrics?.["Savings(25Yr)"]}</td>
                    <td>{data?.metrics?.["Emissions Reductions"]}</td>
                    <td>
                      {data?.title.toLowerCase() === "insulation"
                        ? "-"
                        : data?.metrics?.Payback}
                    </td>
                  </tr>
                );
              })}
            </table>
          </Alignment>
        </Alignment>
      </Alignment>

      <Alignment
        style={{
          textAlign: "right",
          margin: "0px 24px",
          position: "fixed",
          bottom: "0px",
          right: "0px",
          background: "white",
          width: window?.innerWidth > 1350 ? "75.8%" : "71%",
          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <CommonButton
          style={{ borderRadius: "12px", margin: "12px 12px 20px" }}
          onClick={() => {
            navigate("/project-summary");
            sendRecommendationsData(toggle);
          }}
        >
          Submit
        </CommonButton>
      </Alignment>
    </ComponentCard>
  );
};

export default Recommendations;

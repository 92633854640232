import { useContext, useEffect } from "react";
import "./App.css";
import { GlobalContext } from "./context/GlobalContext";
import TabSection from "./pages/TabSection";
import { AppWrapper } from "./styles/appstyles";
import BuildingDetails from "./pages/BuildingDetails";
import BuildingAnalysis from "./pages/BuildingAnalysis";
import Recommendations from "./pages/Recommendations";
import Financing from "./pages/Financing";
import axios from "axios";

import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  const { index } = useContext(GlobalContext);
  console.log("index", index);

  useEffect(() => {
    const login = async () => {
      // const response = await axios.post(
      //   "https://blueurbn-backend.app/user/base/",
      //   {
      //     password1: "demopass",
      //     password2: "demopass",
      //     country: "USA",
      //     email: "demo2email@gmail.com",
      //     first_name: "Demo",
      //     last_name: "Guy",
      //   }
      // );
      // const response = await axios.get("https://blueurbn-backend.app/");
      // console.log("response124", response);
      const response = await axios.post(
        "https://blueurbn-backend.app/user/login/",
        {
          email: "demoemail@gmail.com",
          password: "demopass",
        }
      );

      localStorage?.setItem(
        "access-token",
        JSON.stringify(response.data.token)
      );
      // const response = await axios.get(
      //   `https://blueurbn-backend.app/core/buildings/`,
      //   {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Token ${response1?.data?.token}`, // Include the token in the Authorization header
      //     },
      //   }
      // );

      console.log("response", response);
      // for (let h of response1.headers) {
      //   console.log("abcd", h);
      // }
      // setResponse(response1?.data);
      // console.log("response12345", response1);
    };

    login();
  }, []);

  return (
    <AppWrapper>
      <BrowserRouter>
        <TabSection />
        <Routes>
          <Route path="/" element={<BuildingDetails />} />

          <Route path="building-analysis" element={<BuildingAnalysis />} />
          <Route path="recommendations" element={<Recommendations />} />
          <Route path="project-summary" element={<Financing />} />
        </Routes>
      </BrowserRouter>
    </AppWrapper>
  );
}

export default App;

import BuildingDetails from "./pages/BuildingDetails";
import bolt from "./assets/bolt.png";
import airpurifier from "./assets/airpurifier.png";
import requestQuote from "./assets/requestQuote.png";
// import solar from "./assets/solar_power.png";
// import theaters from "./assets/theaters.png";
// import switchImage from "./assets/switch.png";
// import bulb from "./assets/bulb.png";
// import hvacSolution from "./assets/hvacSolutions.png";
// import { IoSunnyOutline } from "react-icons/io5";
import { FiMapPin, FiBarChart2, FiThumbsUp, FiFileText } from "react-icons/fi";

export const TABS = {
  Address: {
    name: "/",
    icon: <FiMapPin style={{ width: "24px", height: "24px" }} />,
    component: BuildingDetails,
  },
  "Building Analysis": {
    name: "/building-analysis",
    icon: <FiBarChart2 style={{ width: "24px", height: "24px" }} />,
  },
  Recommendations: {
    name: "/recommendations",
    icon: <FiThumbsUp style={{ width: "24px", height: "24px" }} />,
  },
  "Project Summary": {
    name: "/project-summary",
    icon: <FiFileText style={{ width: "24px", height: "24px" }} />,
  },
};

export const BUILDING_ANALYSIS = [
  {
    icon: bolt,
    title: "ANNUAL ENERGY CONSUMPTION",
    mainMetric: "47.3 MWh",
    metrics: ["Save up to 33%"],
  },
  {
    icon: airpurifier,
    title: "EMISSIONS THIS YEAR",
    mainMetric: "120 t of CO2/year",
    metrics: [
      "Reduce 47.29t of CO2/year",
      // `${computeCarbonEmissions()?.lifetime}1,182t lifetime reductions`,
    ],
  },
  {
    icon: requestQuote,
    title: "ANNUAL ENERGY BILL",
    mainMetric: "$5,535",
    metrics: ["Save up to 33%"],
  },
];

export const RECOMMENDATIONS_FILTER = [
  {
    icon: requestQuote,
    title: "Energy",
  },
  {
    icon: bolt,
    title: "Emmissions",
  },
  {
    icon: requestQuote,
    title: "Cost",
  },
];

export const FINANCING_OPTIONS = {
  headers: [
    "color_header",
    "FINANCING OPTIONS",
    "OWN_EQUIPMENT",
    "UPFRONT COST",
    "MONTHLY INSTALLMENT",
    "PAYBACK PERIOD",
  ],
  rowData: [
    {
      title: "Upfront Cash, Hassle free one time payment.",
      own_equiment: "Yes",
      upfront_cost: "$230,000",
      monthly_installment: "$0",
      payback_period: "0 yr",
      color: "#D7F3B9",
    },
    {
      title: "Energy as a Service, Pay a percentage of your energy savings",
      own_equiment: "No",
      upfront_cost: "$0",
      monthly_installment: "Very Low",
      payback_period: "NA",
      color: "#FEDC8F",
    },
    {
      title: "Loan, Own your equipments via monthly instalments",
      own_equiment: "Yes",
      upfront_cost: "$0",
      monthly_installment: "Low",
      payback_period: "2-7 yrs",
      color: "#D4BFFF",
    },
    {
      title: "Lease, Lease your energy equipments",
      own_equiment: "No",
      upfront_cost: "$0",
      monthly_installment: "Very Low",
      payback_period: "NA",
      color: "#D4BFFF",
    },
  ],
};

export const COST_SAVING_GRAPH = [
  {
    title: "Annual",
  },
  {
    title: "Lifetime",
  },
];

export const STANDARD_RESULT = [
  {
    task_id: "22e82afc-37e4-412d-b8ad-957692c4cf75",
    building_id: 2,
    parametric_id: 1,
    design_type: "baseline",
    status: "SUCCESS",
    electricity: 47526.545454545456,
    cost: 0.0,
  },
  {
    task_id: "f4933963-fef0-4c8c-b8e6-9518703f49c9",
    building_id: 2,
    parametric_id: 2,
    design_type: "controls",
    status: "SUCCESS",
    electricity: 41428.5,
    cost: 7560.0,
  },
  {
    task_id: "ca0aeaf5-8858-4ea6-9ff5-5e48d53499b6",
    building_id: 2,
    parametric_id: 3,
    design_type: "hvac",
    status: "SUCCESS",
    electricity: 45042.909090909096,
    cost: 480.0,
  },
  {
    task_id: "443ce530-0b7c-4056-bc87-94edc952b1dd",
    building_id: 2,
    parametric_id: 4,
    design_type: "insulation",
    status: "SUCCESS",
    electricity: 47512.0,
    cost: 13560.0,
  },
  {
    task_id: "23836ae9-5df5-458b-ab38-7f6bcad1d69f",
    building_id: 2,
    parametric_id: 5,
    design_type: "lighting",
    status: "SUCCESS",
    electricity: 40149.09090909091,
    cost: 3600.0,
  },
  {
    task_id: null,
    building_id: 2,
    parametric_id: 7,
    design_type: "solar",
    status: "SUCCESS",
    electricity: 43385,
    cost: 8000.0,
  },
];

export const INCENTIVES = [
  {
    INCENTIVE_ID: 21.0,
    INCENTIVE_NAME:
      "Sales and Use Tax Exemption for Electric Power Generation and Storage Equipment",
    JURISDICTION: "State",
    TECHNOLOGY: "Solar",
    SUB_TECHNOLOGY: "Solar Upgrades",
    INCENTIVE: "7.25% of system cost",
    PROVIDER: "California Department of Tax and Fee Administration",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 0.51,
    ASSUMPTION:
      "https://help.covetool.com/en/articles/4529706-solar-panel-cost-residential-commercial\r\n\r\nSolar Installation costs about $4-$10, therefore taking $7 as the avg. ",
    WEBSITE_LINK: null,
    NOTES: "AB 1817 of 2018",
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 2040.0,
  },
  {
    INCENTIVE_ID: 22.0,
    INCENTIVE_NAME: "Property Tax Exclusion for Solar and Storage Systems",
    JURISDICTION: "State",
    TECHNOLOGY: "Solar",
    SUB_TECHNOLOGY: "Solar Upgrades",
    INCENTIVE: "100% of system value",
    PROVIDER: "California State Board of Equilization",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 0.0,
    ASSUMPTION: null,
    WEBSITE_LINK: "Link",
    NOTES: null,
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 0.0,
  },
  {
    INCENTIVE_ID: 23.0,
    INCENTIVE_NAME: "California Energy Design Assistance",
    JURISDICTION: "State",
    TECHNOLOGY: "All",
    SUB_TECHNOLOGY: "Design Assistance",
    INCENTIVE: "Varies by application",
    PROVIDER: "California State Board of Equilization",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 0.0,
    ASSUMPTION: null,
    WEBSITE_LINK: null,
    NOTES: null,
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 0.0,
  },
  {
    INCENTIVE_ID: 24.0,
    INCENTIVE_NAME: "Golden State Rebates Instant Rebate Program",
    JURISDICTION: "State",
    TECHNOLOGY: "Building Controls & Demand Response",
    SUB_TECHNOLOGY: "Smart Thermostats",
    INCENTIVE: "$75",
    PROVIDER: "CPUC",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 0.18,
    ASSUMPTION: "Assuming 34kBtu (site EUI) is saved, which is roughly 9 kWh.",
    WEBSITE_LINK: "Link",
    NOTES: null,
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 720.0,
  },
  {
    INCENTIVE_ID: 25.0,
    INCENTIVE_NAME: "Golden State Rebates Instant Rebate Program",
    JURISDICTION: "State",
    TECHNOLOGY: "HVAC",
    SUB_TECHNOLOGY: "Small room air conditioners",
    INCENTIVE: "$15",
    PROVIDER: "CPUC",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 0.02,
    ASSUMPTION: null,
    WEBSITE_LINK: "Link",
    NOTES: null,
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 80.0,
  },
  {
    INCENTIVE_ID: 26.0,
    INCENTIVE_NAME: "Golden State Rebates Instant Rebate Program",
    JURISDICTION: "State",
    TECHNOLOGY: "HPWH",
    SUB_TECHNOLOGY: "Heat pump water heaters",
    INCENTIVE: "$900",
    PROVIDER: "CPUC",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 1.2,
    ASSUMPTION:
      "Assuming 1 heat pump water is needed for 1000 - 1500 sqft of about 2 tons\r\n",
    WEBSITE_LINK: "Link",
    NOTES: null,
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 4800.0,
  },
  {
    INCENTIVE_ID: 27.0,
    INCENTIVE_NAME: "Golden State Rebates Instant Rebate Program",
    JURISDICTION: "State",
    TECHNOLOGY: "HPWH",
    SUB_TECHNOLOGY: "Gas tank water heaters",
    INCENTIVE: "$75",
    PROVIDER: "CPUC",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 0.1,
    ASSUMPTION: null,
    WEBSITE_LINK: "Link",
    NOTES: null,
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 400.0,
  },
  {
    INCENTIVE_ID: 21.0,
    INCENTIVE_NAME:
      "Sales and Use Tax Exemption for Electric Power Generation and Storage Equipment",
    JURISDICTION: "State",
    TECHNOLOGY: "Solar",
    SUB_TECHNOLOGY: "Solar Upgrades",
    INCENTIVE: "7.25% of system cost",
    PROVIDER: "California Department of Tax and Fee Administration",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 0.51,
    ASSUMPTION:
      "https://help.covetool.com/en/articles/4529706-solar-panel-cost-residential-commercial\r\n\r\nSolar Installation costs about $4-$10, therefore taking $7 as the avg. ",
    WEBSITE_LINK: null,
    NOTES: "AB 1817 of 2018",
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 2040.0,
  },
  {
    INCENTIVE_ID: 22.0,
    INCENTIVE_NAME: "Property Tax Exclusion for Solar and Storage Systems",
    JURISDICTION: "State",
    TECHNOLOGY: "Solar",
    SUB_TECHNOLOGY: "Solar Upgrades",
    INCENTIVE: "100% of system value",
    PROVIDER: "California State Board of Equilization",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 0.0,
    ASSUMPTION: null,
    WEBSITE_LINK: "Link",
    NOTES: null,
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 0.0,
  },
  {
    INCENTIVE_ID: 23.0,
    INCENTIVE_NAME: "California Energy Design Assistance",
    JURISDICTION: "State",
    TECHNOLOGY: "All",
    SUB_TECHNOLOGY: "Design Assistance",
    INCENTIVE: "Varies by application",
    PROVIDER: "California State Board of Equilization",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 0.0,
    ASSUMPTION: null,
    WEBSITE_LINK: null,
    NOTES: null,
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 0.0,
  },
  {
    INCENTIVE_ID: 24.0,
    INCENTIVE_NAME: "Golden State Rebates Instant Rebate Program",
    JURISDICTION: "State",
    TECHNOLOGY: "Building Controls & Demand Response",
    SUB_TECHNOLOGY: "Smart Thermostats",
    INCENTIVE: "$75",
    PROVIDER: "CPUC",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 0.18,
    ASSUMPTION: "Assuming 34kBtu (site EUI) is saved, which is roughly 9 kWh.",
    WEBSITE_LINK: "Link",
    NOTES: null,
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 720.0,
  },
  {
    INCENTIVE_ID: 25.0,
    INCENTIVE_NAME: "Golden State Rebates Instant Rebate Program",
    JURISDICTION: "State",
    TECHNOLOGY: "HVAC",
    SUB_TECHNOLOGY: "Small room air conditioners",
    INCENTIVE: "$15",
    PROVIDER: "CPUC",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 0.02,
    ASSUMPTION: null,
    WEBSITE_LINK: "Link",
    NOTES: null,
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 80.0,
  },
  {
    INCENTIVE_ID: 26.0,
    INCENTIVE_NAME: "Golden State Rebates Instant Rebate Program",
    JURISDICTION: "State",
    TECHNOLOGY: "HPWH",
    SUB_TECHNOLOGY: "Heat pump water heaters",
    INCENTIVE: "$900",
    PROVIDER: "CPUC",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 1.2,
    ASSUMPTION:
      "Assuming 1 heat pump water is needed for 1000 - 1500 sqft of about 2 tons\r\n",
    WEBSITE_LINK: "Link",
    NOTES: null,
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 4800.0,
  },
  {
    INCENTIVE_ID: 27.0,
    INCENTIVE_NAME: "Golden State Rebates Instant Rebate Program",
    JURISDICTION: "State",
    TECHNOLOGY: "HPWH",
    SUB_TECHNOLOGY: "Gas tank water heaters",
    INCENTIVE: "$75",
    PROVIDER: "CPUC",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 0.1,
    ASSUMPTION: null,
    WEBSITE_LINK: "Link",
    NOTES: null,
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 400.0,
  },
  {
    INCENTIVE_ID: 21.0,
    INCENTIVE_NAME:
      "Sales and Use Tax Exemption for Electric Power Generation and Storage Equipment",
    JURISDICTION: "State",
    TECHNOLOGY: "Solar",
    SUB_TECHNOLOGY: "Solar Upgrades",
    INCENTIVE: "7.25% of system cost",
    PROVIDER: "California Department of Tax and Fee Administration",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 0.51,
    ASSUMPTION:
      "https://help.covetool.com/en/articles/4529706-solar-panel-cost-residential-commercial\r\n\r\nSolar Installation costs about $4-$10, therefore taking $7 as the avg. ",
    WEBSITE_LINK: null,
    NOTES: "AB 1817 of 2018",
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 2040.0,
  },
  {
    INCENTIVE_ID: 22.0,
    INCENTIVE_NAME: "Property Tax Exclusion for Solar and Storage Systems",
    JURISDICTION: "State",
    TECHNOLOGY: "Solar",
    SUB_TECHNOLOGY: "Solar Upgrades",
    INCENTIVE: "100% of system value",
    PROVIDER: "California State Board of Equilization",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 0.0,
    ASSUMPTION: null,
    WEBSITE_LINK: "Link",
    NOTES: null,
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 0.0,
  },
  {
    INCENTIVE_ID: 23.0,
    INCENTIVE_NAME: "California Energy Design Assistance",
    JURISDICTION: "State",
    TECHNOLOGY: "All",
    SUB_TECHNOLOGY: "Design Assistance",
    INCENTIVE: "Varies by application",
    PROVIDER: "California State Board of Equilization",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 0.0,
    ASSUMPTION: null,
    WEBSITE_LINK: null,
    NOTES: null,
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 0.0,
  },
  {
    INCENTIVE_ID: 24.0,
    INCENTIVE_NAME: "Golden State Rebates Instant Rebate Program",
    JURISDICTION: "State",
    TECHNOLOGY: "Building Controls & Demand Response",
    SUB_TECHNOLOGY: "Smart Thermostats",
    INCENTIVE: "$75",
    PROVIDER: "CPUC",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 0.18,
    ASSUMPTION: "Assuming 34kBtu (site EUI) is saved, which is roughly 9 kWh.",
    WEBSITE_LINK: "Link",
    NOTES: null,
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 720.0,
  },
  {
    INCENTIVE_ID: 25.0,
    INCENTIVE_NAME: "Golden State Rebates Instant Rebate Program",
    JURISDICTION: "State",
    TECHNOLOGY: "HVAC",
    SUB_TECHNOLOGY: "Small room air conditioners",
    INCENTIVE: "$15",
    PROVIDER: "CPUC",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 0.02,
    ASSUMPTION: null,
    WEBSITE_LINK: "Link",
    NOTES: null,
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 80.0,
  },
  {
    INCENTIVE_ID: 26.0,
    INCENTIVE_NAME: "Golden State Rebates Instant Rebate Program",
    JURISDICTION: "State",
    TECHNOLOGY: "HPWH",
    SUB_TECHNOLOGY: "Heat pump water heaters",
    INCENTIVE: "$900",
    PROVIDER: "CPUC",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 1.2,
    ASSUMPTION:
      "Assuming 1 heat pump water is needed for 1000 - 1500 sqft of about 2 tons\r\n",
    WEBSITE_LINK: "Link",
    NOTES: null,
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 4800.0,
  },
  {
    INCENTIVE_ID: 27.0,
    INCENTIVE_NAME: "Golden State Rebates Instant Rebate Program",
    JURISDICTION: "State",
    TECHNOLOGY: "HPWH",
    SUB_TECHNOLOGY: "Gas tank water heaters",
    INCENTIVE: "$75",
    PROVIDER: "CPUC",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 0.1,
    ASSUMPTION: null,
    WEBSITE_LINK: "Link",
    NOTES: null,
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 400.0,
  },
  {
    INCENTIVE_ID: 21.0,
    INCENTIVE_NAME:
      "Sales and Use Tax Exemption for Electric Power Generation and Storage Equipment",
    JURISDICTION: "State",
    TECHNOLOGY: "Solar",
    SUB_TECHNOLOGY: "Solar Upgrades",
    INCENTIVE: "7.25% of system cost",
    PROVIDER: "California Department of Tax and Fee Administration",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 0.51,
    ASSUMPTION:
      "https://help.covetool.com/en/articles/4529706-solar-panel-cost-residential-commercial\r\n\r\nSolar Installation costs about $4-$10, therefore taking $7 as the avg. ",
    WEBSITE_LINK: null,
    NOTES: "AB 1817 of 2018",
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 2040.0,
  },
  {
    INCENTIVE_ID: 22.0,
    INCENTIVE_NAME: "Property Tax Exclusion for Solar and Storage Systems",
    JURISDICTION: "State",
    TECHNOLOGY: "Solar",
    SUB_TECHNOLOGY: "Solar Upgrades",
    INCENTIVE: "100% of system value",
    PROVIDER: "California State Board of Equilization",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 0.0,
    ASSUMPTION: null,
    WEBSITE_LINK: "Link",
    NOTES: null,
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 0.0,
  },
  {
    INCENTIVE_ID: 23.0,
    INCENTIVE_NAME: "California Energy Design Assistance",
    JURISDICTION: "State",
    TECHNOLOGY: "All",
    SUB_TECHNOLOGY: "Design Assistance",
    INCENTIVE: "Varies by application",
    PROVIDER: "California State Board of Equilization",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 0.0,
    ASSUMPTION: null,
    WEBSITE_LINK: null,
    NOTES: null,
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 0.0,
  },
  {
    INCENTIVE_ID: 24.0,
    INCENTIVE_NAME: "Golden State Rebates Instant Rebate Program",
    JURISDICTION: "State",
    TECHNOLOGY: "Building Controls & Demand Response",
    SUB_TECHNOLOGY: "Smart Thermostats",
    INCENTIVE: "$75",
    PROVIDER: "CPUC",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 0.18,
    ASSUMPTION: "Assuming 34kBtu (site EUI) is saved, which is roughly 9 kWh.",
    WEBSITE_LINK: "Link",
    NOTES: null,
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 720.0,
  },
  {
    INCENTIVE_ID: 25.0,
    INCENTIVE_NAME: "Golden State Rebates Instant Rebate Program",
    JURISDICTION: "State",
    TECHNOLOGY: "HVAC",
    SUB_TECHNOLOGY: "Small room air conditioners",
    INCENTIVE: "$15",
    PROVIDER: "CPUC",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 0.02,
    ASSUMPTION: null,
    WEBSITE_LINK: "Link",
    NOTES: null,
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 80.0,
  },
  {
    INCENTIVE_ID: 26.0,
    INCENTIVE_NAME: "Golden State Rebates Instant Rebate Program",
    JURISDICTION: "State",
    TECHNOLOGY: "HPWH",
    SUB_TECHNOLOGY: "Heat pump water heaters",
    INCENTIVE: "$900",
    PROVIDER: "CPUC",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 1.2,
    ASSUMPTION:
      "Assuming 1 heat pump water is needed for 1000 - 1500 sqft of about 2 tons\r\n",
    WEBSITE_LINK: "Link",
    NOTES: null,
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 4800.0,
  },
  {
    INCENTIVE_ID: 27.0,
    INCENTIVE_NAME: "Golden State Rebates Instant Rebate Program",
    JURISDICTION: "State",
    TECHNOLOGY: "HPWH",
    SUB_TECHNOLOGY: "Gas tank water heaters",
    INCENTIVE: "$75",
    PROVIDER: "CPUC",
    STATE: "CA",
    COUNTY: "State",
    AMT_DOLLAR_PER_SQFT: 0.1,
    ASSUMPTION: null,
    WEBSITE_LINK: "Link",
    NOTES: null,
    MAX_AMT: null,
    INCENTIVE_DOLLAR_VALUE: 400.0,
  },
];

let id = 0;
export const UPDATED_INCENCTIVES = INCENTIVES?.map((item) => {
  const obj = { ...item, id: id++ };
  return obj;
});

export const formatNumber = (x) => {
  console.log(
    'return x.toString().replace(/B(?=(d{3})+(?!d))/g, ","),',
    x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  );
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

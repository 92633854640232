// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  if (action.type === "SEND_INDEX") {
    return { ...state, index: action.payload };
  } else if (action.type === "SEND_BUILDING_DETAILS") {
    return { ...state, buildingDetails: action.payload };
  } else if (action.type === "SEND_BUILDING_ANALYSIS_DATA") {
    return { ...state, buildingAnalysisData: action.payload };
  } else if (action.type === "SEND_RECOMMENDATIONS_DATA") {
    return { ...state, recommendationsData: action.payload };
  } else if (action.type === "DISPLAY_POLICY_INCENTIVES") {
    return { ...state, policyIncentives: action.payload };
  } else if (action.type === "SEND_PLACES_DETAILS") {
    return { ...state, placeDetail: action.payload };
  } else if (action.type === "SEND_CREATED_BUILDING_DATA") {
    return { ...state, createdBuildingData: action.payload };
  } else if (action.type === "SEND_STANDARD_RESPONSE") {
    return { ...state, standardResponse: action.payload };
  } else if (action.type === "SEND_ENERGY_EMISSION") {
    return { ...state, energyEmission: action.payload };
  } else if (action.type === "SEND_COST_SAVINGS") {
    return { ...state, costSavings: action.payload };
  } else if (action.type === "RECOMMENDATIONS_METRICS") {
    return { ...state, recommendationsMetrics: action.payload };
  } else if (action.type === "SEND_NEW_STRUCTURE") {
    return { ...state, newStructure: action.payload };
  }
};
